import * as React from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { activeOneSliceRemainingInActive } from "./AmFunctions";
import Constants from "Libraries/Constants";
import { IReportFilter } from "Libraries/Interfaces";

ChartJS.register(ArcElement, Tooltip, Legend);

export const doughtChartCenterText = (): any => {
    const plugins = [
      {
        beforeDatasetsDraw: function (chart: any) {
          const { ctx } = chart;
  
          ctx.save();
  
          ctx.font = "bold 15px sans-serif";
          ctx.textBaseline = "middle";
          ctx.textAlign = "center";
  
          ctx.fillText(
            chart.getDatasetMeta(0).data[0]["$context"].raw + "%",
            chart.getDatasetMeta(0).data[0].x,
            chart.getDatasetMeta(0).data[0].y
          );
        },
        afterDraw: function (chart: any) {
          const { ctx } = chart;
  
          ctx.save();
  
          ctx.font = "bold 15px sans-serif";
          ctx.textBaseline = "middle";
          ctx.textAlign = "center";
  
          ctx.fillText(
            chart.getDatasetMeta(0).data[0]["$context"].raw + "%",
            chart.getDatasetMeta(0).data[0].x,
            chart.getDatasetMeta(0).data[0].y
          );
        },
      },
    ];
    return plugins;
};

export const modifiedData = (
    percentages: number[],
    id: number,
    label: string
  ): { labels: string[]; datasets: any[] } => {
    const color: string =
      id === 0
        ? '#65CC56'
        : id === 1
        ? '#f5b61b'
        : '#E50E12';
  
    return {
      labels: [label, ""],
      datasets: [
        {
          label: label,
          data: [percentages[id], 100 - percentages[id]],
          borderWidth: 1,
          backgroundColor: [color, "transparent"],
        },
      ],
    };
};

export const doughtChartOption = (title: string) => {
    return {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          labels: { font: { size: 13 } }
        },
        title: {
          display: false,
          text: `${title} Chart`,
        },
        tooltip: {
          enabled: true,
        },
      },
      interaction: {
        mode: "index" as const,
        intersect: true,
      },
      tooltips: {
        enabled: true,
        // mode: "nearest",
        // intersect: true,
      },
      // hover: {
      //   mode: null,
      // },
      rotation: 0,
      circumference: 360,
      cutout: "75%",
      borderRadius: 7,
    };
};

export const DataMappedContainer: React.FC<{
    children: JSX.Element[];
    title: string;
    score: number;
    caption: string;
    indicator: string;
  }> = (props) => {
    const { children, score, title, caption } = props;
    const color = score === 0 ? 'AA1F00' : score < 50 ? 'E9C621' : '65CC56';
    return (
      <div className="relative flex-1 w-full">
        <h4 className="m-0 w-full block text-center text-black font-semibold">
          {title}
        </h4>
        <div
          className="flex flex-col items-center justify-center m-0 right-1 -top-1 absolute p-0"
        >
          <h6 className="m-0 text-3xl p-0" style={{ color: '#'+color }}>{score}%</h6>
          <span className="text-[10px] font-medium text-black text-center transform -translate-y-0.5">{caption}</span>
        </div>
        <br />
        <div>
          {children}
        </div>
      </div>
    );
  };
  
export function DoughnutCharts({ chartArray, data, chartRef, chartDivId, isUpdation }: any): JSX.Element {
  return (
      <div className="flex flex-row items-center p-2 doughnut-charts" id={chartDivId}>
          {chartArray.map((ch: any, index: number) => (
            <div className="flex flex-row w-1/3 items-center justify-center" key={index}>
              <div className="m-0 w-[190px]">
                <AMDoughnutChart 
                  id={`doughtchart-${index}-${chartDivId}`} label={ch.title} percentage={data[index]}
                  sliceColor={ch.id === 0 ? '#65CC56' : ch.id === 1 ? '#f5b61b' : '#E50E12'} isUpdation={isUpdation}
                />
              </div>
            </div>
          ))}
      </div>
  );
}

export function AMDoughnutChart({ percentage, label, sliceColor, id, isUpdation }: { id: string; percentage: number, label: string; sliceColor: string; isUpdation?: number; }): JSX.Element {
  React.useLayoutEffect(() => {

    if (!document.getElementById(id)) {
      return;
    }

    let root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(am5percent.PieChart.new(root, {
      layout: root.verticalLayout,
      innerRadius: am5.percent(78)
    }));

    // Create series
    let series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: "value",
      categoryField: "category",
      alignLabels: false,
      radius: am5.percent(100),
    }));

    // Set rounded corners on slices
    series.slices.template.setAll({
      templateField: "sliceSettings"
    });

    // Apply colors to pie slices
    const seriesColors: am5.ColorSet | undefined | any = series.get("colors");
    seriesColors.set("colors", [
      am5.Color.fromAny(sliceColor), am5.Color.fromAny("#ffffff")
    ]);
    
    series.labels.template.setAll({
      textType: "circular", centerX: 0, centerY: 0
    });

    series.labels.template.set("visible", false);
    series.ticks.template.set("visible", false);
    
    // Set data
    series.data.setAll([
      { value: percentage, category: label, sliceSettings: { cornerRadius: 0, innerCornerRadius: 0 } }, 
      { value: 100 - percentage, category: "", sliceSettings: { cornerRadius: 0, innerCornerRadius: 0 } }
    ]);

    // Customize tooltip label font size
    series.slices.template.set("tooltip", am5.Tooltip.new(root, {
      labelText: "{label}: {value}%[/]"
    }));
    (series.slices.template.get("tooltip") as any).label.setAll({
      fontSize: 12 // Set desired font size here
    });

    // Create legend
    let legend = root.container.children.unshift(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      layout: root.horizontalLayout,
      paddingTop: 5
    }));

    // Customize legend labels to show only category
    legend.labels.template.setAll({
      text: "{category}",
      populateText: true
    });

    let filteredDataItems = series.dataItems.filter((dataItem: any) => dataItem.get("category") !== "");
    legend.data.setAll(filteredDataItems);

    series.appear(1000, 100);

    legend.labels.template.setAll({
      fontSize: 13, // Reduce the font size
      textAlign: "center", // Center-align the text
    });

    legend.valueLabels.template.set("forceHidden", true);

    legend.events.on('click', () => {
      series.labels.template.set("forceHidden", true);
      series.ticks.template.set("forceHidden", true);
      legend.valueLabels.template.set("forceHidden", true);
    });

    // Add central label
    chart.seriesContainer.children.push(am5.Label.new(root, {
      text: percentage + "%",
      fontSize: 20,
      centerX: am5.percent(50),
      centerY: am5.percent(50),
      populateText: true,
    }));

    return () => {
      root.dispose();
    };

  }, [id, percentage, label, sliceColor, isUpdation]);

  return (
    <div className="relative" id={id} style={{ width: "180px", height: "200px" }}>
      <div className="absolute bg-white z-30 w-[66px] h-[27px] left-0 bottom-0 rounded-2xl"></div>
    </div>
  );
}

export function AMPieDoughnutChart({ data, id, isUpdation, styles, isLegendVisible, isTicksEnable, onSelection, storedFilters }: { 
  id: string; data: { mappings: string; percentage: number; mappings_list?: any[]; }[]; isUpdation?: number; styles: any;
  isLegendVisible: boolean; isTicksEnable?: boolean; onSelection?: (obj: any) => void; storedFilters?: IReportFilter[];
}): JSX.Element {

  const colorsUpdate = React.useCallback(() => {
    const selectedCategories: string[] = storedFilters?.map(v => v.title) ?? [];
    let root = am5.getRootById(id);
    if (root && selectedCategories.length > 0) {
      let chart = root.container.children.values.find((child: any) => child instanceof am5percent.PieChart);
      if (chart) {
        const staticColors: string[] = ['#3ca951', '#4269d0', '#efb118', '#ff725c', '#6cc5b0', '#ef5017', '#0991cd', '#4e81bc', '#f6d458', '#3ca988'];
        let series = chart.series.values[0];

        if (series) {
          series.slices.each(function(slice: any, ind: number) {
            if (selectedCategories?.includes(slice.dataItem?.dataContext?.category)) {
              slice.set("fill", am5.color(staticColors[ind]));
              slice.set("active", true);
            } else {
              slice.set("fill", am5.color("#f1f1f1"));
              slice.set("active", false);
            }
          });
        }
      }
    }
  }, [storedFilters, id]);

  React.useEffect(() => {
    colorsUpdate();
  }, [colorsUpdate]);

  React.useLayoutEffect(() => {

    if (!document.getElementById(id)) {
      return;
    }

    let root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let chart = root.container.children.push(am5percent.PieChart.new(root, {
      layout: root.verticalLayout,
      innerRadius: am5.percent(71),
    }));

    // Create series
    let series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: "value",
      categoryField: "category",
      alignLabels: true,
      legendValueText: "{valuePercentTotal.formatNumber('0p')}",
    }));

    activeOneSliceRemainingInActive(series, onSelection);

    const staticColors: string[] = ['#3ca951', '#4269d0', '#efb118', '#ff725c', '#6cc5b0', '#ef5017', '#0991cd', '#4e81bc', '#f6d458', '#3ca988'];
    const seriesColors: am5.ColorSet | undefined | any = series.get("colors");
    seriesColors.set("colors", data.map((_, ind: number) => am5.Color.fromString(staticColors[ind])));

    series.labels.template.setAll({
      text: "[fontSize: 16px]{category}: {value.formatNumber('0p')}[/]",
      textType: "regular",
      centerX: 0,
      centerY: 0,
      maxWidth: 240,
      oversizedBehavior: 'wrap'
    });

    // Set data
    series.data.setAll(data.map((v) => {
      return { category: v.mappings, value: v.percentage, data_by_list: v?.mappings_list }
    }));

    series.slices.template.set("tooltip", am5.Tooltip.new(root, {
      labelText: "[fontSize: 12px]{category}: {value.formatNumber('0p')}[/]"
    }));

    // Hide labels and ticks
    series.labels.template.set("visible", isTicksEnable ? isTicksEnable : false);
    series.ticks.template.set("visible", false);

    if (isLegendVisible) {
      // Create legend
      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 0,
        marginBottom: isLegendVisible ? 30 : 0,
      }));

      legend.data.setAll(series.dataItems);

      legend.events.on('click', () => {
        series.labels.template.set("forceHidden", true);
        series.ticks.template.set("forceHidden", true);
      });
    }

    // Play initial series animation
    series.appear(1000, 100);
    
    return () => {
      root.dispose();
    };

  }, [id, data, isUpdation, isLegendVisible]);

  return (
    <div className="relative" id={id} style={styles}>
      <div className="absolute bg-white z-30 w-[55px] h-[20px] left-0 bottom-0 rounded-xl"></div>
    </div>
  );
}
