import React, {useState, useEffect} from 'react';

import withAuthentication from "Hoc/WithAuthentication";
import { Paths } from "Libraries/Route";
import {Button} from "Components/Button/Button";
import MasterLayout from 'Hoc/MasterLayout';
import DocumentTitle from 'Components/DocumentTitle/DocumentTitle';
import { Input } from 'Components/Input/Input';
import { PaperClipIcon } from '@heroicons/react/20/solid';
import { CustomTable } from 'Components/CustomTable/CustomTable';
import Modal from "Components/Modal/Modal";
import ApiRequest from 'Services/ApiRequest';
import { IStpListItem } from 'Libraries/Interfaces';
import PaginatedRecord from 'Components/PaginatedRecord/PaginatedRecord';
import { NavLink } from 'react-router-dom';
import Methods from 'Libraries/CommonMethodsUI';
import { toast } from 'react-toastify';

const StpScreen:React.FC<any> = ({ user }) => {
    
    const [file, setFile] = React.useState<File | null>(null);
    const [tiv, setTiv] = React.useState<string>('');
    const [loadingUpload, setLoadingUpload] = React.useState<boolean>(false);

    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [total, setTotal] = React.useState<number>(1);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [selected, setSelected] = React.useState<IStpListItem | undefined>(undefined);
    const [submissionList, setSubmissionList] = React.useState<{ copy: IStpListItem[], original: IStpListItem[] }>({ copy: [], original: [] });

    const fetchRecords = React.useCallback(async (f: boolean) => {
        setLoading(f);
        try {
            const res = await ApiRequest.getSTPSovsLists(undefined, {
                page_size: 10, page: currentPage
            });
            setTotal(res.data.count);
            setSubmissionList({ copy: res.data.results, original: res.data.results });            
            setLoading(false);
        }
        catch (err) {
            setLoading(false);            
        }
    }, [currentPage]);

    useEffect(() => {
        fetchRecords(true);
    }, [fetchRecords]);

    const onSubmitSov = React.useCallback(async () => {
        if (!tiv || !file) {
            toast.error("Fields with (*) are required.");
        } else {
            setLoadingUpload(true);
            const formData = new FormData();
            formData.append("tiv", tiv.split(',').join(''));
            formData.append("file", file);

            try {
                const res = await ApiRequest.postSovForSTPProcess(formData);
                toast.success(res.data.message);
                setIsOpenModal(false);
                setTiv('');
                setFile(null);
                fetchRecords(false);
                setLoadingUpload(false);
            }
            catch (err) { 
                setLoadingUpload(false);
                toast.error("Unable to process.");
            }
        }
    }, [tiv, file, fetchRecords]);

    const openFile = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, url: string): void => {
        e.stopPropagation();
        if (url) {
            window.open(url, '_blank');
        }
    };

    const headers: string[] = ['Control Number', 'Status', 'TIV', 'SOV File', 'Output File', 'Submitted on'];
    return (
        <MasterLayout mainTitle='Upload' mainClassName="overflow-hidden" className='px-4 sm:px-6 lg:px-8' userEmail={user?.email as string}>
           <DocumentTitle title='Upload'> 

                <div className="flex items-center justify-end">
                    <Button
                        onClick={() => setIsOpenModal(true)} title={'Upload'} disabled={false}
                        className="rounded-md text-sm inline-flex items-center justify-center px-4 bg-primary-blue-dark hover:bg-opacity-60 transition-all duration-200 h-7 mb-4 font-semibold text-white shadow-sm focus-visible:outline"
                    />
                </div>
                <div className={`flow-root remove-scrollbar`}>
                    <CustomTable 
                        maxHeight="max-h-[75vh] remove-scrollbar" loading={loading} emptyList={submissionList.original.length === 0}
                        headers={{ data: headers, hasIcons: false, modified: (
                            <tr className='border-b-2 border-primary-grey'>
                                { headers.map((head: string, index) => (
                                    <th key={index} scope="col" style={{ minWidth: '120px' }} className="py-3 w-1/5 pl-3 text-left text-sm font-bold text-[#343A40]">
                                        {head}
                                    </th>
                                )) }
                            </tr>
                        ) }}
                    >
                        {submissionList.original.map((item: IStpListItem) => (
                            <tr className={`hover:bg-[#FFEB8C] ${selected?.control_number === item.control_number && 'bg-[#FFEB8C]'} transition-all duration-200 border-b border-primary-grey cursor-pointer`}
                                key={item.id}
                            >
                                <td className={`whitespace-nowrap py-3 px-3 w-[${100/6}%] cursor-pointer`}
                                >
                                    <span className="underline font-bold text-xs text-[#155D9C]">{item.control_number}</span>
                                    {/* <NavLink to={{ pathname: item.control_number }} className="underline font-bold text-xs text-[#155D9C]">{item.control_number}</NavLink> */}
                                </td>
                                <td className={`whitespace-nowrap w-[${100/6}%] py-3 px-3 text-xs text-primary-grey-10`}>{item?.status}</td>
                                <td className={`whitespace-nowrap w-[${100/6}%] py-3 px-3 text-xs ${item.tiv ? 'text-[#343A40]' : 'text-[#A4A4A4]'} font-medium`}>{item.tiv ? `$ ${item.tiv?.toLocaleString("en-US")}` : 'N/A'}</td>
                                <td className={`whitespace-nowrap w-[${100/6}%] py-3 px-3 text-xs text-primary-grey-10`} title={item?.file ?? 'File not found.'}>
                                    <img onClick={(e) => openFile(e, item?.file)} alt={`${item?.file}-icon`} width="26px" height="28px" src={Methods.getFileIcon(item?.file)} className="mr-4" />
                                </td>
                                <td className={`whitespace-nowrap w-[${100/6}%] py-3 px-3 text-xs text-primary-grey-10`} title={item?.result_file ?? 'File not found.'}>
                                    <img onClick={(e) => openFile(e, item?.result_file)} alt={`${item?.result_file}-icon`} width="26px" height="28px" src={Methods.getFileIcon(item?.result_file)} className="mr-4" />
                                </td>
                                <td className={`whitespace-nowrap w-[${100/6}%] py-3 px-3 text-xs text-primary-grey-10`}>
                                    {!item?.created_on ? "" : Methods.convertUTCtoLocalDate(item?.created_on)?.toString()}
                                </td>
                            </tr>
                        ))}
                    </CustomTable>
                </div>
                <PaginatedRecord
                    totalPages={Math.ceil(total / 10)} total={total} listLength={submissionList.original.length}
                    onPageChange={(p) => {
                        setCurrentPage(p);
                    }} currentPage={currentPage} perPage={10}
                    onDisplayChange={(n: number) => setSubmissionList({ ...submissionList, original: submissionList.copy.slice(0, n) })}
                />

                <Modal open={isOpenModal} setOpen={(e: boolean) => setIsOpenModal(e)} isSmall={true}>
                    <div className="flex flex-col w-[480px] p-3 m-auto rounded-md shadow">
                        <div className={`flex ${ file?.name ? '' : 'mb-3' }`}>
                            <button
                                type="button"
                                className="group -my-2 -ml-2 relative overflow-hidden inline-flex items-center rounded-full px-3 py-2 text-left text-gray-400"
                            >
                                <PaperClipIcon className="-ml-1 mr-2 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                <span className="text-sm italic text-gray-500 group-hover:text-gray-600">Upload a file*</span>
                                <input placeholder="Upload File" title="Upload File" type="file" name="myfile" className="absolute left-0 right-0 top-0 bottom-0 opacity-0 cursor-pointer" onChange={(e: any) => setFile(e.target.files[0])} />
                            </button>
                        </div>
                        { file?.name && (
                            <span className="text-xs text-black mb-3"> {file?.name} <button onClick={() => setFile(null)} className="bg-gray-100 rounded-md py-1 px-3 ml-3">Clear</button> </span>
                        )}
                        <Input
                            id="tiv-field" name="tiv" type="text" required={true} isHandleOnBlur={true}
                            value={tiv} autoComplete="off" placeholder="Enter TIV*"
                            onChange={(e)=> {
                                setTiv(e.target.value);
                            }}
                            className={`block w-full caret-black text-black mb-3 appearance-none rounded-lg border border-black px-3 py-2 
                            placeholder-black bg-transparent shadow-sm focus:border-gray-300 focus:outline-none sm:text-sm h-10 input-modify`}
                        />
                        <Button
                            onClick={() => onSubmitSov()} title={'Submit'} disabled={loadingUpload} loading={loadingUpload}
                            className="px-4 w-36 mx-auto inline-flex items-center hover:bg-opacity-60 transition-all duration-200 justify-center h-7 rounded-full bg-primary-blue-dark text-sm text-white"
                        />
                    </div>
                </Modal>
            </DocumentTitle>
        </MasterLayout>
    )
};

export default withAuthentication(StpScreen, Paths.login, 10);
