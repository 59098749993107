import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5 from "@amcharts/amcharts5";

export function circularLabelsInsideLargeWidthSlice(series: am5percent.PieSeries, match: number): void {
    series.labels.template.adapters.add("y", function(y, target: any) {
        var dataItem = target.dataItem;
        if (dataItem) {
          var tick = dataItem.get("tick");
          if (tick) {
            if (dataItem.get("valuePercentTotal") < match) {
              target.set("forceHidden", true);
              tick.set("forceHidden", true);
            }
            else {
              target.set("forceHidden", false);
              tick.set("forceHidden", false);
            }
          }
          return y;
        }
    });
}

export function activeOneSliceRemainingInActive(series: am5percent.PieSeries, onSelection?: (obj: any) => void): void {
  series.slices.template.events.on("click", function(ev) {
    const clickedSlice: any = ev.target;

    // Call the callback function if provided
    if (onSelection) {      
      onSelection(clickedSlice.dataItem?.dataContext);
    } else {
      // Iterate over all slices
      series.slices.each(function(slice) {
        if (slice != ev.target && slice.get("active")) {
          slice.set("active", false);
        }
      });
    }    
  });
}
