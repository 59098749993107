import React, { useEffect, useState, FC } from "react";

import GoogleMapReact from "google-map-react";
import Methods from "Libraries/CommonMethodsUI";
import { IReportMapData } from "Libraries/Interfaces";
import TIVLegends from "./TivLegends";
import ReactDOM from "react-dom";

interface IGoogleMap {
  markers: IReportMapData[];
  mapHeight: string;
}

const GoogleMap: FC<IGoogleMap> = ({ markers, mapHeight }) => {

  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number; }>({ lat: 0, lng: 0 });
  const [defaultZoom, setdefaultZoom] = useState<number>(5);
  const [mBounds, setmBounds] = useState();
  const [gmap, setGmap] = useState<any>();
  const [mapMarkers, setMapMarkers] = useState<any[]>([]);  // Store markers in state

  useEffect(() => {
    onInitialLoad();
    document.addEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  useEffect(() => {
    if (gmap) {
      // Remove existing control if present
      document.getElementById("gmap-refresh")?.remove();
      document.getElementById("gmap-colorpallete")?.remove();

      const centerControlDiv = document.createElement('div');
      const centerControl = resetButton(gmap);
      centerControlDiv.appendChild(centerControl);
      
      const indicationDiv = document.createElement('div');
      indicationDiv.id = "gmap-colorpallete";
      indicationDiv.style.marginLeft = '8px';
      indicationDiv.style.zIndex = '10px';
      indicationDiv.style.width = '160px';
      indicationDiv.style.display = 'none';

      const indications = (
        <TIVLegends key={Date.now()} data={getColorPallete(markers)} />
      );
      ReactDOM.render(indications, indicationDiv);
      
      // Append to map controls
      gmap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv);
      gmap.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(indicationDiv);

      // Update markers as needed
      updateMarkers(gmap, true);
    }
  }, [gmap, markers]);

  function handleFullscreenChange() {
    const isFullscreen = document.fullscreenElement !== null;
    const ele = document.getElementById("gmap-colorpallete");
    if (isFullscreen && ele) {
      ele.style.display = 'flex';
    }
    else {
      (ele as any).style.display = 'none';
    }
  };

  const onInitialLoad = (): void => {
    const filterEmptyLatLng = markers.filter(mark => parseFloat(mark.lat));
    // findout max and min latLong coordinates 
    const latlist = filterEmptyLatLng.map((item: any) => parseFloat(item?.lat));
    const lnglist = filterEmptyLatLng.map((item: any) => parseFloat(item?.lng));
    let maxlat = Math.max(...latlist);
    let minlat = Math.min(...latlist);
    let maxlng = Math.max(...lnglist);
    let minlng = Math.min(...lnglist);
    // set initial zoom of Google map based on max and min coordinates
    getZoom(maxlat, maxlng, minlat, minlng);
    setMapCenter({ lat: minlat, lng: maxlng });
  };

  /**
   * this function calculates TIV for each marker ans sort it in ascending order.
   * based on these TIV, it defines the color of each marker using color value stepper.
   * example : hsl(${colorStep.step : 120}, 100%, 50%)
   * @param markers 
   */
  const getColorPallete = (markers: IReportMapData[]): { color: string; start: number; end: number; }[] => {

    const sortedData = markers.map(v => v.tiv).sort((a, b) => a - b);

    const min = 0;
    const max = Math.max(...sortedData);
    const colors = ['#207A1A', '#6ECB52', '#EEFF41', '#FF9100', '#C11212'];
  
    if (min === max) {
      return [{ start: 0, end: max, color: colors[0] }];
    }

    // Define the number of bins
    const numBins = 5;
    const binSize = Math.ceil(sortedData.length / numBins);
    const bins: number[][] = [];

    // Create bins with equal frequencies
    for (let i = 0; i < numBins; i++) {
      const binStart = i * binSize;
      const binEnd = binStart + binSize;

      if (sortedData.slice(binStart, binEnd)[1] !== 0) {
        bins.push(sortedData.slice(binStart, binEnd));
      }
    }
  
    const values = [];
    for (let k = 0; k < bins.length; k++) {
      values.push({ start: k === 0 ? k : (bins[k - 1].at(-1) as number) + 1, end: (bins[k].at(-1) as number), color: colors[k] });
    }
    
    return values;
  };

  const latRad = (lat: number): number => {
    var sin = Math.sin(lat * Math.PI / 180);
    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  };

  // calculate map zoom level based on max latlong and min latlong, so that all the markers fit in single view 
  const getZoom = (lat_a: number, lng_a: number, lat_b: number, lng_b: number): void => {
    let latDif = Math.abs(latRad(lat_a) - latRad(lat_b));
    let lngDif = Math.abs(lng_a - lng_b);

    let latFrac = latDif / Math.PI;
    let lngFrac = lngDif / 360;

    let lngZoom = Math.log(1 / latFrac) / Math.log(2);
    let latZoom = Math.log(1 / lngFrac) / Math.log(2);
    setdefaultZoom(lngZoom > latZoom ? latZoom + 2 : lngZoom + 2);
  };

  const getMarkerIcon = (color: string, _maps: any, scale: number) => {
    return {
      path: "M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z",
      fillColor: color,
      class: "noooooo",
      fillOpacity: 1,
      strokeWeight: 0.5,
      strokeColor: '#000',
      rotation: 0,
      scale: scale,
      anchor: new _maps.Point(15, 30),
    };
  };

  const getMarkerContent = (marker: IReportMapData, confidence: string | undefined): string => {
    let _cova: number = parseFloat(marker?.covA) ? Math.round(parseFloat(marker?.covA)) : 0;
    let _covb: number = parseFloat(marker?.covB) ? Math.round(parseFloat(marker?.covB)) : 0;
    let _covc: number = parseFloat(marker?.covC) ? Math.round(parseFloat(marker?.covC)) : 0;
    let _covd: number = parseFloat(marker?.covD) ? Math.round(parseFloat(marker?.covD)) : 0;

    const locatnName = marker?.locationName ? `
    &nbsp;&nbsp;<strong>Location Name: </strong>${marker?.locationName}`
      : "";

    return `<div className="info-window">
      <p style="margin-top:0; margin-bottom: 4px"><strong>ID: </strong>${marker?.ID}${locatnName}</p>
      
      <p style="margin-top:0; margin-bottom: 4px"><strong>Address: </strong>${marker?.streetAddress ? `${marker?.streetAddress}, ${marker?.city}` : 'Unknown'}, ${marker?.state} ${marker?.postal}</p>

      <p style="margin-top:0; margin-bottom: 4px"><strong>County: </strong>${marker?.county ? marker?.county : 'Unknown'},&nbsp;&nbsp;&nbsp;<strong>Couty Tier: </strong>${marker?.countyTier ? marker?.countyTier : 'Unknown'}</p>

      <p style="margin-top:0; margin-bottom: 4px"><strong>Coverages:</strong> $${Methods.numberWithCommas(_cova)} (Building Limit); $${Methods.numberWithCommas(_covb)} (Other Limit); $${Methods.numberWithCommas(_covc)} (Contents Limit); $${Methods.numberWithCommas(_covd)} (BI Limit);</p>

      <p style="margin-top:0; margin-bottom: 4px"><strong>TIV:</strong> $ ${Methods.numberWithCommas(marker?.tiv as number)} </p>

      <p style="margin-top:0; margin-bottom: 4px"><strong>Construction:</strong> ${marker?.construction ? marker?.construction : "Unknown"}&nbsp;&nbsp;<strong>Occupancy:</strong> ${marker?.occupancy ? marker?.occupancy : "Unknown"}</p>

      <p style="margin-top:0; margin-bottom: 4px"><strong>Number of Stories:</strong> ${marker.noOfStories[0] ? marker.noOfStories[0] : "Unknown"}&nbsp;&nbsp;<strong>Country:</strong> ${marker?.countryCode} </p>
      <p style="margin-top:0; margin-bottom: 4px"><strong>Year Built:</strong> ${(marker?.yearBuilt || parseInt(marker?.yearBuilt) === 0) ? marker.yearBuilt : "Unknown"}</p>
      <p style="margin-top:0; margin-bottom: 4px"><strong>Floor Area:</strong> ${(marker?.floorArea || parseInt(marker?.floorArea) === 0) ? parseFloat(marker.floorArea).toLocaleString('en-US') : "Unknown"}&nbsp;&nbsp;<strong>Geocode Preference:</strong> ${marker?.geocodePreference}</p> 
    </div>`
  };

  const updateMarkers = (map: any, isAddResetButton: boolean) => {
    // Clear previous markers from map
    mapMarkers.forEach(marker => marker.setMap(null));
    const newMarkers = addMarkers(map);
    setMapMarkers(newMarkers);
  };

  // once map is loaded, sets marker and its infowindow on google map
  const addMarkers = (map: any): any[] => {
    const _maps = (window as any).google.maps;
    const bounds = new (window as any).google.maps.LatLngBounds();

    const pallete = getColorPallete(markers.filter(m => m?.lat && m?.lng));

    const newMarkers = markers.filter(f => f.lat && f.lng).map((marker: IReportMapData, index: number) => {
      bounds.extend({ lat: parseFloat(marker.lat), lng: parseFloat(marker.lng) });

      let colorStep = pallete.find((item) => item.end >= (marker?.tiv as number));
      const svgMarker = getMarkerIcon(colorStep?.color as string, _maps, 0.6);

      let infoWindow = new _maps.InfoWindow({
        content: getMarkerContent(marker, undefined),
      });

      let mark = new _maps.Marker({
        position: { lat: parseFloat(marker.lat), lng: parseFloat(marker.lng) },
        map, draggable: false, icon: svgMarker,
      });

      _maps.event.addListener(map, "click", function() {
        infoWindow.close();
      });

      mark.addListener("click", () => {
        mark.setZIndex(google.maps.Marker.MAX_ZINDEX + 1);
        infoWindow.open({
          anchor: mark,
        });
      });

      let lastDblClickTime = 0;
      // const doubleClickThreshold = 5000; // 500 milliseconds

      // Double click event listener
      mark.addListener("dblclick", () => {
        const currentTime = Date.now();

        if (currentTime - lastDblClickTime < currentTime) {
          // Second double-click detected
          setdefaultZoom(5);
          map.fitBounds(mBounds);
        } else {
          // First double-click detected
          infoWindow.close();
          setdefaultZoom(18);
          setMapCenter({ lat: parseFloat(markers[index].lat), lng: parseFloat(markers[index].lng) });
        }

        lastDblClickTime = currentTime;
      });

      mark.addListener("mouseover", () => {
        mark.setZIndex(google.maps.Marker.MAX_ZINDEX + 1);
        mark.setIcon(getMarkerIcon(colorStep?.color as string, _maps, 0.8));
      });

      mark.addListener("mouseout", () => {
        mark.setIcon(svgMarker);
      });

      mark.addListener("dragend", async () => {
        // await onMarkerDrag(marker.ID - 1, mark.getPosition().lat(), mark.getPosition().lng());
        infoWindow.close();

        mark.addListener("click", () => {
          infoWindow.setContent(getMarkerContent(markers[index], 'User Supplied'));
          infoWindow.open(_maps, mark);
          setdefaultZoom(18);
        });
      });

      return mark;
    });

    setGmap(map);
    setmBounds(bounds);
    map.fitBounds(bounds);
    
    return newMarkers;
  };

  function resetButton(map: any) {
    const controlButton = document.createElement('button');
  
    // Set CSS for the control
    controlButton.id = "gmap-refresh"
    controlButton.style.backgroundColor = '#fff';
    controlButton.style.border = '2px solid #fff';
    controlButton.style.borderRadius = '3px';
    controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlButton.style.color = 'rgb(25,25,25)';
    controlButton.style.cursor = 'pointer';
    controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlButton.style.fontSize = '16px';
    controlButton.style.lineHeight = '38px';
    controlButton.style.margin = '2px 9px 2px';
    controlButton.style.padding = '0 5px';
    controlButton.style.textAlign = 'center';
    
    // Set the icon for the button (using an SVG or an image URL)
    controlButton.innerHTML = '<img src="https://static.thenounproject.com/png/3648091-200.png" alt="Reset" style="width: 28px; height: 28px;">';
    
    controlButton.title = 'Click to recenter the map';
    controlButton.type = 'button';
  
    // Setup the click event listeners: simply set the map to the desired center
    controlButton.addEventListener('click', () => {
      addMarkers(map);
    });
  
    return controlButton;
  }

  const defaultMapOptions = {
    zoomControl: false,
    mapTypeControl: true,
    scaleControl: false,
    streetViewControl: true,
    rotateControl: false,
    fullscreenControl: true,
  };

  if (markers.length === 0) return null;

  return (
    <div className={`w-full relative ${mapHeight} gmap-container`}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY as string }}
        center={mapCenter}
        zoom={defaultZoom}
        options={defaultMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          setGmap(map);  // Set map instance
          updateMarkers(map, true);  // Initialize markers
        }}
        style={{ borderRadius: '6px' }}
      />
    </div>
  );
};

export default GoogleMap;
