import React, {Fragment} from "react";

import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {Menu, Transition} from "@headlessui/react";
import {RxAvatar} from "react-icons/rx";
import {BsArrowLeft} from "react-icons/bs";
import Constants from "Libraries/Constants";
import CommonMethodsUI from "Libraries/CommonMethodsUI";
import {IGeneral} from "Libraries/State.Interfaces";
import {TokenService} from "Libraries/TokenService";
import {isLogoutAction, isSetUserDetailsAction} from "Redux/Action";
import {useDispatch} from "react-redux";
import { Images } from "Libraries/Images";
import ApiRequest from "Services/ApiRequest";
import Methods from "Libraries/CommonMethodsUI";

interface Props {
    setOpenModal: (flag: boolean, key: string) => void;
    isSideBarOpen: boolean;
    userEmail: string;
    navigate?: (() => void | undefined) | undefined;
    mainTitle?: string | undefined;
}

const TopBar:React.FC<Props> = ({ setOpenModal, userEmail, navigate, isSideBarOpen, mainTitle }) => {

    const dispatch = useDispatch();

    async function selectUserNavigation(item: IGeneral): Promise<void> {
        if(item.id === 1) {
            const user = await ApiRequest.getUserDetails();
            if (user.data){
                dispatch(isSetUserDetailsAction(user.data));
            }
            setOpenModal(true, 'profile');
        }
        if(item.id === 2) setOpenModal(true, 'password');
        if(item.id === 3) { 
            TokenService.clearTokens(); 
            dispatch(isLogoutAction());
            window.location.reload();
        }
    };

    return(
        <div className={`fixed z-10 right-0 top-0 flex h-16 flex-shrink-0 bg-white shadow-lg ${ isSideBarOpen ? 'left-0 lg:left-60 xl:left-60' : 'left-0 lg:left-20 xl:left-20' }`}>            
            <div className="flex flex-1 justify-between pr-4 pl-4">
                <div className="flex flex-1">
                    <form className="hidden w-full md:ml-0" action="#" method="GET">
                        <label htmlFor="search-field" className="sr-only">
                            Search
                        </label>
                        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                            </div>
                            <input
                                id="search-field"
                                className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                                placeholder="Search"
                                type="search"
                                name="search"
                            />
                        </div>
                    </form>
                </div>

                <div className="z-0 hidden"></div>

                <div className="flex items-center w-full justify-between">
                    <img
                        className="cursor-pointer block lg:hidden xl:hidden" width={"50px"}
                        src={Images.logo} alt="Wizard Analytics"
                    />
                    <BsArrowLeft onClick={() => navigate && navigate()} style={{ zIndex: 1000 }} className={`text-xl cursor-pointer ${!navigate && 'hidden'}`} />
                    <span className={`text-black text-lg font-medium ${mainTitle === 'Submissions' ? 'text-left' : 'text-center max-w-md truncate'}`}>
                        { mainTitle }
                    </span>
                    <div className="flex flex-row items-center justify-start">
                        <span className="text-black text-sm mr-3">
                            Timezone: <span className="font-medium">{Methods.getGMTTimezone()}</span>
                        </span>
                        <span className="text-black text-sm mr-5">{userEmail ?? ''}</span>
                        {/* Profile dropdown */}
                        <Menu as="div" className="relative">
                            {({ open }) => (
                                <React.Fragment>
                                    <Menu.Button 
                                        onClick={() => {
                                            if (!open) {
                                                document.getElementById('g-map3')?.classList.remove('z-10');
                                                document.getElementById('g-map3')?.classList.add('z-0');
                                            }
                                            else {
                                                document.getElementById('g-map3')?.classList.remove('z-0');
                                                document.getElementById('g-map3')?.classList.add('z-10');
                                            }
                                        }}
                                        className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                        <span className="sr-only">Open user menu</span>
                                        <RxAvatar className={"text-2xl border-none outline-none focus:border-none text-gray-400 "} />
                                    </Menu.Button>
                                    { open && (
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {Constants.userNavigation.map((item) => (
                                                    <Menu.Item key={item.name}>
                                                        {({ active }) => (
                                                            <div
                                                                className={CommonMethodsUI.classNames(
                                                                    active ? 'bg-gray-100' : '',
                                                                    'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                                                )}
                                                                onClick={()=> selectUserNavigation(item) }
                                                            >
                                                                {item.name}
                                                            </div>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </Menu.Items>
                                        </Transition>
                                    )}
                                </React.Fragment>
                            )}
                        </Menu>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TopBar;
