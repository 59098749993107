import React from "react";

import { HomeIcon, UsersIcon } from "@heroicons/react/24/outline";
import {ICompany, IScreenFilter, ISubmission, IUserProfile} from "./Interfaces";
import {statusEnum} from "./Enums";
import CommonMethodsUI from "./CommonMethodsUI";
import Methods from "./CommonMethodsUI";
import {Paths} from "./Route";

import { CgList } from 'react-icons/cg';
import { FiSearch, FiUpload } from 'react-icons/fi';
import { MdEmail, MdBrush, MdConstruction } from 'react-icons/md';
import { FaUser, FaUsers } from 'react-icons/fa';
import { BsBuildingsFill, BsBriefcaseFill } from 'react-icons/bs';
import { AiFillSchedule } from 'react-icons/ai';
import { HiBuildingOffice2 } from 'react-icons/hi2';
import { RxTimer } from 'react-icons/rx';

class CommonConstants{

    public secret: string = "sOvwIzaRd";

    public navigation = (isUploadShow: boolean): any[] => {
        return [
            { id: 1, name: 'Dashboard', path: Paths.dashboard, icon: HomeIcon, current: true, condition: true },
            { id: 2, name: 'Companies', path: Paths.companies, icon: UsersIcon, current: false, condition: false },
            { id: 3, name: 'Users', path: Paths.users, icon: FaUsers, current: false, condition: false },
            { id: 4, name: 'Template History', path: Paths.templateHistory, icon: RxTimer, current: false, condition: false },
            { id: 5, name: 'Reported Construction', path: Paths.reportedConstruction, icon: MdConstruction, current: false, condition: false },
            { id: 6, name: 'Reported Occupancy', path: Paths.reportedOccupancy, icon: HiBuildingOffice2, current: false, condition: false },
            { id: 7, name: 'Subscription', path: Paths.subscription, icon: MdBrush, current: false, condition: false },
            { id: 8, name: 'Triage', path: Paths.triage, icon: FiSearch, current: false, condition: false },
            { id: 9, name: 'Invoice', path: Paths.invoice, icon: CgList, current: false, condition: false },
            { id: 10, name: 'Upload', path: Paths.stp, icon: FiUpload, current: false, condition: isUploadShow }
        ];
    }

    public screenFilters: IScreenFilter[] = [
        { id: 1, value: 'Created By', key: 'created_by', isDropdown: false }, 
        { id: 2, value: 'Company', key: 'company', isDropdown: true }, 
        { id: 3, value: 'Search By Description', key: 'search_by_description', isDropdown: false }, 
        { id: 4, value: 'Email', key: 'email', isDropdown: false },
        { id: 5, value: 'Status', key: 'status', isDropdown: false }, 
        { id: 6, value: 'Location Limit', key: 'location_limit', isDropdown: false }
    ];

    public submissionPerPageList = [{ id:1, name: '5', disabled: false }];

    public submissionHeaders = (person?: ISubmission) => {
        return [
                    {id:1, name: 'Company', data: person?.company},
                    {id:2, name: 'Control Number', data: person?.control_number},
                    // {id:3, name: 'Version', data: person?.version},
                    // {id:4, name: 'SOV', data: person?.sov_filename},
                    {id: 5, name: 'Status', data: person?.status,
                        field: <span className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 
                            ${person?.status === statusEnum[statusEnum.Submitted] ? 'text-blue-800 bg-blue-100' :
                            person?.status === statusEnum[statusEnum.Assigned] ? 'text-pink-800 bg-pink-100' :
                            person?.status === statusEnum[statusEnum.In_Process] ? 'text-purple-800 bg-purple-100' :
                            person?.status === statusEnum[statusEnum.Exposures_Ready] ? 'text-lime-800 bg-lime-100' :
                             person?.status === statusEnum[statusEnum.Modeled] ? 'text-green-800 bg-green-100' :
                                            'text-red-800 bg-red-100'}`}>
                             {person?.status && CommonMethodsUI.noDataAvailable(person?.status)}
                             </span>},
                    {id: 6, name: 'Assigned To', data: CommonMethodsUI.capitalize(person?.assigned_to)},
                    {id: 7, name: 'Actions'},
                    {id: 8, name: 'Attachments'},
                    {id: 9, name: 'Exposure Files'},
                    // @ts-ignore
                    {id: 10, name: 'Submitted On', data: person ? CommonMethodsUI.getEasternTimeZone(person.created_on) : '' },
               ]
    };

    public geocodeConfidences: { type: string; color: string; priority: number; id: number; }[] = [
        { color: "rgba(229, 14, 18, .7)", priority: 0, type: "", id: 1 },
        { color: "#E50E12", priority: 1, type: "Unknown", id: 2 },
        { color: "rgba(225, 198, 36, .7)", priority: 2, type: "Zip5", id: 3 },
        { color: "rgba(225, 198, 36, 1)", priority: 3, type: "Zip6", id: 4 },
        { color: "rgba(255, 255, 0, 1)", priority: 4, type: "Zip7", id: 5 },
        { color: "rgba(245, 182, 27, .7)", priority: 5, type: "Zip8", id: 6 },
        { color: "#f5b61b", priority: 6, type: "Zip9", id: 7 },
        { color: "rgba(255, 165, 0, 1)", priority: 7, type: "Street", id: 8 },
        { color: "rgba(39, 201, 92, .6)", priority: 8, type: "Parcel", id: 9 },
        { color: "rgba(39, 201, 92, .8)", priority: 9, type: "Rooftop", id: 10 },
        { color: "#27C95C", priority: 10, type: "User Supplied", id: 11 }
    ];

    public CompanyHeaders = (company?: ICompany) => {
        return [
            {id: 1, name: 'ID', data: company?.id ?? ''},
            {id: 2, name: 'Company', data: company?.company_name},
            {id: 3, name: 'Code', data: company?.company_code},
            {id: 4, name: 'Custom Template', data: company?.custom_template},
            {id: 5, name: 'AIR Template', data: company?.air_template},
            {id: 6, name: 'RMS Template', data: company?.rms_template}
        ];
    };

    public getStatusId = (s: string): number => {
        return s === statusEnum[statusEnum.Submitted] ? 1 : s === statusEnum[statusEnum.Assigned] ? 2 : 
        s.split(' ').join('_') === statusEnum[statusEnum.In_Process] ? 3 :
        s.split(' ').join('_') === statusEnum[statusEnum.Exposures_Ready] ? 4 :
        s === statusEnum[statusEnum.Modeled] ? 5 : s === statusEnum[statusEnum.Quoted] ? 6 : 
        s === statusEnum[statusEnum.Bound] ? 7 : 0;
    }

    public submissionsStatuses = (status: string | any): { id: number; name: string; condition?: boolean; }[] => {
        return [
            { id: statusEnum.Submitted + 1, name: statusEnum[statusEnum.Submitted], condition: true },
            { id: statusEnum.Assigned + 1, name: statusEnum[statusEnum.Assigned], condition: true },
            { id: statusEnum.In_Process + 1, name: statusEnum[statusEnum.In_Process].split('_').join(' '), condition: true },
            { id: statusEnum.Exposures_Ready + 1, name: statusEnum[statusEnum.Exposures_Ready].split('_').join(' '), condition: true },
            { id: statusEnum.Modeled + 1, name: statusEnum[statusEnum.Modeled], condition: this.getStatusId(status) === 4 },
            { id: statusEnum.Quoted + 1, name: statusEnum[statusEnum.Quoted], condition: this.getStatusId(status) === 5 },
            { id: statusEnum.Bound + 1, name: statusEnum[statusEnum.Bound], condition: this.getStatusId(status) === 6 },
        ];
    }

    public userProfile = (person?: IUserProfile) => {
        return [
            {id: 1, name: 'Name', data: Methods.capitalize(person?.first_name) + ' ' + Methods.capitalize(person?.last_name), show: true, icon: <FaUser className="w-5 h-5 text-[#676767]" /> },
            {id: 2, name: 'Email', data: person?.email, show:true, icon: <MdEmail className="w-5 h-5 text-[#676767]" /> },
            {id: 3, name: 'Company', data: person?.company_id?.company_name, show: true, icon: <BsBuildingsFill className="w-5 h-5 text-[#676767]" /> },
            {id: 4, name: 'Role', data: person?.role?.split('_')?.join(' '), show: true, icon: <BsBriefcaseFill className="w-5 h-5 text-[#676767]" /> },
            {id: 5, name: 'Last Login', data: Methods.getEasternTimeZone(person?.last_login), show: true, icon: <AiFillSchedule className="w-5 h-5 text-[#676767]" /> }
        ]
    };

    public statusLogsHeaders = (person?: any) => {
        return [
            {id:1, name: 'Status', data: person?.status },
            {id:2, name: 'Created By', data: person?.email },
            {id:3, name: 'Created On', data: Methods.getEasternTimeZone(person?.created_on) }
        ]
    };

    public barChartSeriesColors: { label: string; color: string; identify: string; }[] = [
        { label: "Locations", color: "rgba(246, 212, 88, 0.8)", identify: "location_count" },
        { label: "Building Limit", color: "rgba(246, 212, 88, 0.8)", identify: "building" },
        { label: "Other Limit", color: "rgba(78, 129, 188, 0.8)", identify: "other" },
        { label: "Contents Limit", color: "rgba(9, 145, 205, 0.8)", identify: "contents" },
        { label: "BI Limit", color: "rgba(239, 80, 23, 0.8)", identify: "bi" }
    ];

    public userNavigation = [
        { name: 'Your Profile', id: 1 },
        { name: 'Change Password', id: 2 },
        { name: 'Sign out', id: 3 },
    ];

    public lookup: { value: number; symbol: string; }[] = [
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
    ];

    public barChartModeTypes: { [key: string]: string; } = {
        huZone: "HU Zone",
        eqZone: "EQ Zone",
        countries: "Country",
        state: "State",
        cTier: "County Tier",
        county: "Mapped County",
        reportedCounty: "Reported County",
        postal: "Postal",
        city: "City",
        construction: "Construction",
        occupancy: "Occupancy",
        yearBuilt: "Year Built",
        stories: "Number of Stories",
        geoConfidance: "Geocode Confidence",
    };

    public yearBuiltDataVisulization: string[] = [
        "<1995",
        "1995 - 2001",
        "2002 - 2008",
        "2009+",
        "Unknown",
    ];
    public storiesDataVisulization: string[] = [
        "1",
        "2 - 3",
        "4 - 7",
        "8 - 14",
        "15+",
        "Unknown",
    ];

    public reportFooterStringHTML: string = `
    <div style="width: 100%; height: 115px; display: flex; flex-direction: column; justify-content: flex-end;">
        <div style="display: block; height: 100px; width: 100%; border-top: 2px solid #f6d458;">
            <div style="justify-content: center; display: flex; flex-direction: row; align-items: center; position: relative; padding-top: 4px;">
                <div style="flex: 1; padding-left: 8px;">
                    <div style="display: flex; flex-direction: row; align-items: center;">
                        <div style="padding-right: 4px; font-weight: 600; font-size: 13px; display: none;"></div>
                        <div style="font-size: 13px; font-weight: 600;">https://sovwizard.com/</div>
                    </div>
                    <div style="display: flex; flex-direction: row; align-items: center;">
                        <div style="padding-right: 4px; font-weight: 600; font-size: 13px; display: none;"></div>
                        <div style="font-size: 13px; font-weight: 600;">contact@wizardanalytics.io</div>
                    </div>
                </div>
                <div style="padding-right: 4px;">
                    <img alt='logo' src='https://stage.excel.sovwizard.com/assets/report-logo.png' width='190px' />
                </div>
            </div>
            <div>
                <img alt='logo' src='https://stage.excel.sovwizard.com/assets/report-line.png' width='100%' style="transform: translateY(-5px);" />
            </div>
            <div>
                <span style="padding-left: 8px; font-size: 10px; display: block; transform: translateY(-4px); color: #949494;">Copyright &copy; 2023 Wizard Analytics All rights reserved.</span>
            </div>
        </div>
    </div>
    `;

    public getDataByOptions = {
        "hu_zone": "HU Zone",
        "eq_zone": "EQ Zone",
        "eq_zone2": "EQ Zone 2",
        "fl_zone": "FL Zone",
        "fl_zone2": "FL Zone 2",
        "country": "Country",
        "state": "State",
        "county_tier": "County Tier",
        "county_tier2": "County Tier 2",
        "reported_county": "Reported County",
        "mapped_county": "Mapped County",
        "postal": "Postal",
        "city": "City",
        "construction_code": "Construction Code",
        "occupancy_code": "Occupancy Code",
        "year_built": "Year Built",
        "number_of_stories": "Number of Stories",
        "geocode_confidence": "Geocode Confidence"
    };

    public apiStatuses = {
        API_SUCCESS: 200,
        TEMP_REQUIRED: 307,
        API_CREATED: 201,
        API_DELETE: 204,
        MULTIPLE_RESPONSES: 300,
        BAD_REQUEST: 400,
        API_UNAUTHORISED: 401,
        FORBIDDEN: 403,
        API_DOES_NOT_EXIST: 404,
        UNABLE_PROCESS: 504
    };

    public amchartCountryVerifyForCountyMap = [
        { match: 'Mexico', add: 'mexico' },
        { match: 'Jalisco', add: 'jalisco' },
        { match: 'Canada', add: 'canada' },
        { match: 'United States of America', add: 'usa' }
    ];
}

const Constants = new CommonConstants();

export default Constants



