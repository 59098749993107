import * as React from 'react';

import DocumentTitle from 'Components/DocumentTitle/DocumentTitle';
import MasterLayout from 'Hoc/MasterLayout';
import { useLocation, useParams } from 'react-router-dom';
import { Paths } from 'Libraries/Route';
import ApiRequest from 'Services/ApiRequest';
import { SpinnerLoader } from 'Components/Loader/Loader';
import GoogleMap from 'Components/Maps/GoogleMap';
import Methods from 'Libraries/CommonMethodsUI';
import { Tabs, TabWithRemove } from 'Components/Tabs/Tabs';
import withAuthentication from 'Hoc/WithAuthentication';
import HeatMap, { ChoroplethHeatMap} from 'Components/Maps/HeatMap';
import { BarChartSection, GeoCodeConfidenceSection, GuageChartSection, MappingDoughnutSection, TIVChartSection } from './ReportElements';
import { useFetchCurrentSubmission } from 'Hooks/UseFetch';
import Constants from 'Libraries/Constants';
import { IReportData, IReportFilter, IReportMapData } from 'Libraries/Interfaces';
import { useDispatch } from 'react-redux';
import { setAppLoaderAction } from 'Redux/Action';
import { SwitchShortToggle } from 'Components/SwitchToggle/SwitchToggle';

var barChartMode: string = "State";
var barChartMode1: string = "State";
var filters: IReportFilter[] = [];

function ReportAnalyticsBE({ user, navigate }: any): JSX.Element {

    const f = localStorage.getItem("report-filters");
    filters = f ? JSON.parse(f).filter((v: any) => v !== null) : [];

    const dispatch = useDispatch();

    const { controlnumber, version } = useParams<{ controlnumber: string; version: string; }>();
    const { search } = useLocation();

    const [mapType, setMapType] = React.useState<number>(2);
    const [reportData, setReportData] = React.useState<{ [key: string]: any; } | undefined>(undefined);
    const [dataCoverageByGeography, setDataCoverageByGeography] = React.useState<any[]>([]);
    const [dataCoverageByGeography1, setDataCoverageByGeography1] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isIgnoreZeroTivLocations, setIsIgnoreZeroTivLocations] = React.useState<boolean>(false);
    const [byLocationOrTiv, setByLocationOrTiv] = React.useState<boolean>(false);

    const [constructions, setConstructions] = React.useState<{ [key: string]: any; }>({});
    const [occupancies, setOccupancies] = React.useState<{ [key: string]: any; }>({});
    const [geoCodeConfidence, setGeoCodeConfidence] = React.useState<{ [key: string]: any; }>({});

    const { submission } = useFetchCurrentSubmission(controlnumber as string);

    const prepareFilters = (filters: IReportFilter[], ignoreZeroTivLocs: boolean, byLocOrTIV: boolean, shouldReturnWithFilters: boolean): { [key: string]: any; } => {
        const params: { [key: string]: any; } = { 
            version: version, control_number: controlnumber, by_tiv: byLocOrTIV,
        };

        let result = {};

        if (shouldReturnWithFilters)
            result = filters.reduce((acc: { [key: string]: any; }, item: IReportFilter) => {
                if (item.filter?.toString()?.includes('&') && item.title.toLowerCase() === 'other') {
                    const split = item.filter?.toString().split('&').filter(f => f);
                    split.forEach((v) => {
                        const [key, value] = v.split('=');
                        if (acc[key]) {
                            acc[key].push(value);
                        } else {
                            acc[key] = [value];
                        }
                    });
                } else {
                    if (acc[item.key]) {
                        acc[item.key].push(item.filter);
                    } else {
                        acc[item.key] = [item.filter];
                    }
                }
                return acc;
            }, {});

        if (ignoreZeroTivLocs) params['ignore_zero_tiv'] = true;

        return { ...params, ...result };
    }

    const deleteSpecificFilter = (params: { [key: string]: any; }, selection: string) => {
        const updatedParams = filters.reduce((acc: { [key: string]: any; }, item: IReportFilter) => {
            if (item.key === selection) {
                delete acc[item.key];
            }
            return acc;
        }, params);
        return updatedParams;
    }

    const initialRun = React.useCallback(async () => {    
        if (filters.length > 0 && filters.find(v => v.key)) {
            setLoading(true);
            
            const params = prepareFilters(filters, isIgnoreZeroTivLocations, byLocationOrTiv, true);
            const baseParams = { version: version, control_number: controlnumber };
            const dataByParam = { data_by: Object.entries(Constants.getDataByOptions).find(([_, val]) => val === barChartMode)?.[0] ?? '' };
    
            const apis = [
                ApiRequest.reportCoverageData(params),
                ApiRequest.reportMapData(params),
                ApiRequest.reportDataCompleteness(params),
                ApiRequest.reportDataQuality(params),
                ApiRequest.reportGeoCodeData(deleteSpecificFilter(params, "geocode_confidence")),
                ApiRequest.reportConstructionsData(deleteSpecificFilter(params, "construction_code")),
                ApiRequest.reportOccupancyData(deleteSpecificFilter(params, "occupancy_code")),
                ApiRequest.reportDataCoverageByGeography(filters.some(f => f.verify === 'bar') ? { ...baseParams, ...dataByParam } : { ...params, ...dataByParam })
            ];
    
            try {
                const [
                    coverageData, mapData, dataCompleteness, dataQuality,
                    geoCodeData, constructionsData, occupancyData, geographyCoverageData
                ] = await Promise.all(apis);
    
                setReportData({
                    coverages: coverageData.data,
                    mapData: mapData.data?.data ?? [],
                    dataCompleteness: dataCompleteness.data,
                    dataQuality: dataQuality.data,
                    dataMapped: (await ApiRequest.getDTReportJson(baseParams))?.data?.data_transparency_report_json?.dataMapped ?? undefined
                });
                setGeoCodeConfidence(geoCodeData?.data?.data ?? {});
                setConstructions(constructionsData?.data ?? {});
                setOccupancies(occupancyData?.data ?? {});
                setDataCoverageByGeography(geographyCoverageData?.data?.data ?? []);
                setDataCoverageByGeography1(geographyCoverageData?.data?.data ?? []);

                setLoading(false);
            } catch(err) {
                setLoading(false);
            }
        } else {
            getReportData([], true, false, false, "");
            getBarChartData([], barChartMode, false, isIgnoreZeroTivLocations, byLocationOrTiv, true);
            getBarChartData([], barChartMode1, true, isIgnoreZeroTivLocations, byLocationOrTiv, true);
        }
    }, []);

    React.useEffect(() => {
        initialRun();
        return () => {
            filters = [];
            setLoading(true);
            barChartMode = "State";
            barChartMode1 = "State";
        }
    }, [initialRun]);

    async function getReportData(filters: IReportFilter[], flag: boolean, ignoreZeroTivLocs: boolean, byLocOrTIV: boolean, pieChartRequest: string): Promise<void> {
        if (version && controlnumber) {
            try {
                const params = prepareFilters(filters, ignoreZeroTivLocs, byLocOrTIV, true);
                
                if (Object.keys(params).length === 3) {
                    setLoading(flag);
                }

                const res: any = await ApiRequest.getDTReportJson(params);
                const result: IReportData = res?.data?.data_transparency_report_json;

                const apis = [
                    ApiRequest.reportCoverageData(params), ApiRequest.reportMapData(params), 
                    ApiRequest.reportDataCompleteness(params), ApiRequest.reportDataQuality(params)
                ];

                if (pieChartRequest === "geo") {
                    apis.push(ApiRequest.reportConstructionsData(params));
                    apis.push(ApiRequest.reportOccupancyData(params));
                }
                else if (pieChartRequest === "const") {
                    apis.push(ApiRequest.reportGeoCodeData(params));
                    apis.push(ApiRequest.reportOccupancyData(params));
                }
                else if (pieChartRequest === "occ") {
                    apis.push(ApiRequest.reportGeoCodeData(params));
                    apis.push(ApiRequest.reportConstructionsData(params));
                }
                else {
                    apis.push(ApiRequest.reportGeoCodeData(params));
                    apis.push(ApiRequest.reportConstructionsData(params));
                    apis.push(ApiRequest.reportOccupancyData(params));
                }
                
                const response = await Promise.all(apis);

                setReportData({
                    coverages: response[0].data, mapData: response[1].data?.data ?? [],
                    dataCompleteness: response[2].data, dataQuality: response[3].data, dataMapped: result?.dataMapped ?? undefined
                });

                if (pieChartRequest === "geo") {
                    setConstructions(response[4]?.data ?? {});
                    setOccupancies(response[5]?.data ?? {});
                }
                else if (pieChartRequest === "const") {
                    setGeoCodeConfidence(response[4]?.data?.data ?? {});
                    setOccupancies(response[5]?.data ?? {});
                }
                else if (pieChartRequest === "occ") {
                    setGeoCodeConfidence(response[4]?.data?.data ?? {});
                    setConstructions(response[5]?.data ?? {});
                }
                else {
                    setGeoCodeConfidence(response[4]?.data?.data ?? {});
                    setConstructions(response[5]?.data ?? {});
                    setOccupancies(response[6]?.data ?? {});
                }
                            
                setLoading(false);
                dispatch(setAppLoaderAction(false));
            }
            catch(e) {
                setLoading(false);
                dispatch(setAppLoaderAction(false));
            }
        }
    };

    async function getBarChartData(filters: IReportFilter[], mode: string, isCompare: boolean, ignoreZeroTivLocs: boolean, byLocOrTIV: boolean, isPerformRequest: boolean): Promise<void> {
        if (version && controlnumber) {
            const selected = Object.entries(Constants.getDataByOptions).find(([_, val]) => val === mode);
            const params = prepareFilters(filters, ignoreZeroTivLocs, byLocOrTIV, true);
            params['data_by'] = selected?.[0];

            if (isPerformRequest) {
                const response = await ApiRequest.reportDataCoverageByGeography(params);
                
                if (isCompare) {
                    setDataCoverageByGeography1(response.data?.data ?? []);
                }
                else {
                    setDataCoverageByGeography(response.data?.data ?? []);
                }
            }
        }
    };

    const onSelectionFilters = (key: string, obj: any, verify: string, isPerformBCRequest: boolean, pieChartRequest: string): void => {

        dispatch(setAppLoaderAction(true));
        const modified: IReportFilter = { 
            key, filter: obj?.category === "" ? "" : (obj?.category || obj?.label || obj?.name || (typeof obj?.value === 'string' ? obj?.value : '') || ''), verify,
            title: obj?.category || obj?.label || obj?.name || obj?.value || ''
        };

        if (obj?.category === "Other") {
            let str = '';
            obj?.data_by_list?.forEach((f: any) => {
                str = str + `&${key}=${f}`;
            });
            modified['filter'] = str;
        }

        if (filters.find(f => f.key === key)) { // For multi-select
            filters = filters.find(f => f.key === key && f.title === modified.title) ? filters : [ modified, ...filters ];
        }
        else {
            filters = [ modified, ...filters ];
        }

        localStorage.setItem("report-filters", JSON.stringify(filters));

        getReportData(filters, false, isIgnoreZeroTivLocations, byLocationOrTiv, pieChartRequest);
        getBarChartData(filters, barChartMode, false, isIgnoreZeroTivLocations, byLocationOrTiv, isPerformBCRequest);
    };

    const onRemoveFilter = (index: number, pieChartRequest: string, isPerformBCRequest: boolean): void => {
        dispatch(setAppLoaderAction(true));

        const copyFilters = filters;
        delete copyFilters[index];

        filters = copyFilters.filter(f => f !== null);

        localStorage.setItem("report-filters", JSON.stringify(filters));

        getReportData(filters, false, isIgnoreZeroTivLocations, byLocationOrTiv, pieChartRequest);
        getBarChartData(filters, barChartMode, false, isIgnoreZeroTivLocations, byLocationOrTiv, isPerformBCRequest);
    };

    const memoizedGeoCodeConfidence = React.useMemo(() => Object.entries(geoCodeConfidence)?.map(([key, value]: [string, any]) => {
        return {
            label: key,
            count: value?.count,
            tiv: value?.tiv,
            percentage: value?.percentage
        };
    }), [geoCodeConfidence]);

    const memoizedMapMarkers = React.useMemo(() => {
        return reportData?.mapData?.map((v: any) => {
            const lat = isNaN(parseFloat(v.lat)) ? "0" : Math.max(-90, Math.min(90, parseFloat(v.lat))).toFixed(4).toString();
            const lng = isNaN(parseFloat(v.lng)) ? "0" : (((parseFloat(v.lng) + 180) % 360 + 360) % 360 - 180).toFixed(4).toString();
            const tiv = [v?.covA, v?.covB, v?.covC, v?.covD]
              .map(val => parseFloat(val) || 0)
              .reduce((sum, num) => sum + num, 0);
          
            return { ...v, count: 100, lat, lng, tiv: Math.round(tiv) };
          }).filter((f: IReportMapData) => f?.lat !== "90.0000" && f?.lat !== "0")
            .sort((a: IReportMapData, b: IReportMapData) => a.tiv - b.tiv);
    }, [reportData?.mapData]);

    if (loading || !reportData) {
        return (
            <MasterLayout mainTitle={`${submission?.name_insured}`} navigate={() => navigate(`${Paths.dashboard}/${controlnumber}${search}`)} className='px-4 sm:px-6 lg:px-8' userEmail={user?.email as string}>
                <DocumentTitle title={`${submission?.name_insured}`}>
                    <div className="flex flex-col py-5 justify-center items-center">
                        { loading ? <SpinnerLoader adjustment={true} enhance="text-black text-base" /> : (
                            <span className="text-base text-black">Not found.</span>
                        )}
                    </div>
                </DocumentTitle>
            </MasterLayout>
        )
    }

    return (
        <MasterLayout mainTitle={`${submission?.name_insured  ? submission?.name_insured : ''}`} isReportTab={true} 
            className='px-2 relative' mainClassName="overflow-x-hidden" userEmail={user?.email as string}
            navigate={() => {
                filters = [];
                navigate(`${Paths.dashboard}/${controlnumber}${search}`);
                localStorage.removeItem("report-filters");
            }}
        >
            <DocumentTitle title={`${submission?.name_insured  ? submission?.name_insured : ''}`}>

                <div className="flex flex-row items-start justify-between px-3 mt-2">
                    { filters.length > 0 && (
                        <div className="flex flex-row items-center flex-wrap w-[70%]">
                            <span className="font-semibold mr-2 text-sm">Filters:</span>
                            { Object.entries(filters.reduce((acc: { [key: string]: any; }, item: IReportFilter) => {
                                if (acc[item.key]) {
                                    acc[item.key] = { ...item, labels: [...acc[item.key].labels, item.title]};
                                } else {
                                    acc[item.key] = { ...item, labels: [item.title]};
                                }
                                return acc;
                            }, {})).map(([key, value]: [string, IReportFilter], ind) => (
                                <div className='flex flex-row items-center mr-1 pt-[2px]' key={key+ind}>
                                    <span className="capitalize text-sm mr-1"> {key.split('_').join(' ')} / </span>
                                    { (value.labels as string[])?.map(v => (
                                        <TabWithRemove className="mr-1" key={key} onClose={() => {
                                            const ff = filters.filter(f => f.key === key && f.verify === value.verify);

                                            onRemoveFilter(
                                                filters.findIndex(f => f.key === key && f.title === v),
                                                ff.length > 1 ? value?.verify as string : "",
                                                false
                                            );
                                        }} title={v} />
                                    )) }
                                </div>
                            )) }
                            <button onClick={() => {
                                dispatch(setAppLoaderAction(true));
                                localStorage.removeItem("report-filters");
                                filters = [];
                                getReportData(filters, false, isIgnoreZeroTivLocations, byLocationOrTiv, "");
                                getBarChartData(filters, barChartMode, false, isIgnoreZeroTivLocations, byLocationOrTiv, true);
                                setTimeout(() => {
                                    getBarChartData(filters, barChartMode1, true, isIgnoreZeroTivLocations, byLocationOrTiv, true);
                                }, 1000);
                            }} className="inline-flex ml-3 items-center justify-center px-3 rounded-full bg-primary-blue-dark hover:bg-opacity-60 transition-all duration-200 h-6 text-xs font-semibold text-white shadow-sm focus-visible:outline">
                                Reset
                            </button>
                        </div>
                    )}
                    <div className="flex flex-row items-center ml-auto divide-x-2 divide-gray-200 gap-2">
                        <SwitchShortToggle enabled={byLocationOrTiv as boolean} setEnabled={(f: boolean) => {
                            dispatch(setAppLoaderAction(true));
                            setByLocationOrTiv(f);
                            getReportData(filters, false, isIgnoreZeroTivLocations, f, "");
                            getBarChartData(filters, barChartMode, false, isIgnoreZeroTivLocations, f, true);
                            getBarChartData(filters, barChartMode1, true, isIgnoreZeroTivLocations, f, true);
                        }} title='By TIV' isTwiceTitle="By Locations" />
                        <SwitchShortToggle enabled={isIgnoreZeroTivLocations as boolean} setEnabled={(f: boolean) => {
                            dispatch(setAppLoaderAction(true));
                            setIsIgnoreZeroTivLocations(f);
                            getReportData(filters, false, f, byLocationOrTiv, "");
                            getBarChartData(filters, barChartMode, false, f, byLocationOrTiv, true);
                            getBarChartData(filters, barChartMode1, true, f, byLocationOrTiv, true);
                        }} title='Ignore 0 TIV Locations' />
                    </div>
                </div>

                <div className="grid grid-cols-9 gap-2 h-[90vh] p-2">

                    <div className="col-span-4 relative bg-inherit border-2 border-gray-200 rounded-md p-2">
                        <Tabs 
                            activeClassName="mb-2 mr-3 button-39" inActiveClassName="mb-2 mr-3 button-39" onChange={(v) => {
                                setMapType(v);
                            }} active={mapType} 
                            tabs={[ { id: 1, name: 'Google Map' }, { id: 2, name: 'Heat Map' }, { id: 3, name: 'World Map' } ]} 
                        />

                        { mapType === 1 ? (
                            <GoogleMap mapHeight='h-[82vh]' markers={memoizedMapMarkers} />
                        ) : mapType === 2 ? (
                            <HeatMap markers={memoizedMapMarkers} />
                        ) : mapType === 3 ? (
                            <ChoroplethHeatMap 
                                countyMatrix={Methods.removeDublicatesInArray([])} styles={{ width: '100%', height: '82vh' }} 
                                id="clustered-map-4" markers={memoizedMapMarkers} 
                            />
                        ) : null }
                    </div>

                    <div className="col-span-5 relative bg-inherit h-[90vh]">

                        <div className='grid grid-cols-4 gap-2 h-[16vh]'>
                            <TIVChartSection 
                                tiv={reportData?.coverages?.tiv ?? 0} coverages={reportData?.coverages?.coverages} 
                            />
                            <GuageChartSection report={{ 
                                dataMapped: reportData?.dataMapped, dataCompleteness: reportData?.dataCompleteness,
                                dataQuality: reportData?.dataQuality
                            }} />
                        </div>

                        <div className='relative col-span-3 border-2 border-gray-200 rounded-md pt-2 pl-2 my-2 h-[46vh]'>
                            { dataCoverageByGeography?.length > 0 && (
                                <BarChartSection
                                    barChartMode={barChartMode} locationCount={reportData?.coverages?.location_count ?? 0} report={dataCoverageByGeography}
                                    onSelection={(obj) => onSelectionFilters(
                                        Object.entries(Constants.getDataByOptions).find(([_, val]) => val === barChartMode)?.[0] as string, obj, 'bar', false, ""
                                    )} 
                                    isModifiedData={false} barChartMode1={barChartMode1} totalLocations1={reportData?.coverages?.location_count ?? 0}
                                    setBarChartMode1={(str) => {
                                        barChartMode1 = str;
                                        getBarChartData(filters, str, true, isIgnoreZeroTivLocations, byLocationOrTiv, true);
                                    }} 
                                    report1={dataCoverageByGeography1} byLocationOrTiv={byLocationOrTiv} 
                                    storedFilters={filters.filter(f => f.verify === 'bar')}
                                    setBarChartMode={(str) => {
                                        barChartMode = str;
                                        getBarChartData(filters, str, false, isIgnoreZeroTivLocations, byLocationOrTiv, true);
                                    }}
                                />
                            )}
                        </div>

                        <div className='grid grid-cols-3 gap-2 h-[26vh]'>
                            <div className='relative col-span-1 border-2 border-gray-200 rounded-md p-2'>
                                <GeoCodeConfidenceSection 
                                    data={memoizedGeoCodeConfidence} byLocationOrTiv={byLocationOrTiv}
                                    onSelection={(obj) => onSelectionFilters("geocode_confidence", obj, 'geo', true, 'geo')}
                                    storedFilters={filters.filter(f => f.verify === 'geo')}
                                />
                            </div>
                            <div className='relative col-span-1 border-2 border-gray-200 rounded-md p-2'>
                                <MappingDoughnutSection 
                                    onSelection={(k) => onSelectionFilters('construction_code', k, 'const', true, 'const')} chartId={`mappings-modal-${1}`}
                                    activeTab={1} item={{ title: 'Construction', value: (constructions?.pie_chart_data ?? [])?.length ?? 0 }} 
                                    data={constructions?.pie_chart_data ?? []} 
                                    tableData={constructions?.mapping_data?.map((v: any) => {
                                        return { construction: v?.raw_construction, scheme: v?.scheme, construction_code_id: v?.code, mapping: v?.description }
                                    })}
                                    storedFilters={filters.filter(f => f.verify === 'const')}
                                />
                            </div>
                            <div className='relative col-span-1 border-2 border-gray-200 rounded-md p-2'>
                                <MappingDoughnutSection 
                                    onSelection={(k) => onSelectionFilters('occupancy_code', k, 'occ', true, 'occ')} chartId={`mappings-modal-${2}`}
                                    activeTab={2} item={{ title: 'Occupancy', value: (occupancies?.pie_chart_data ?? [])?.length ?? 0 }} 
                                    data={occupancies?.pie_chart_data ?? []} 
                                    tableData={occupancies?.mapping_data?.map((v: any) => {
                                        return { occupancy: v?.raw_occupancy, scheme: v?.scheme, occupancy_code_id: v?.code, mapping: v?.description }
                                    })}
                                    storedFilters={filters.filter(f => f.verify === 'occ')}
                                />
                            </div>
                        </div>

                    </div>

                </div>

            </DocumentTitle>
        </MasterLayout>
    );
}

const MemoizedReportAnalyticsBE = React.memo(ReportAnalyticsBE);

export default withAuthentication(MemoizedReportAnalyticsBE, Paths.login, 1);