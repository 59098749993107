import * as React from "react";

import './App.css';

import { Routes, Route,} from "react-router-dom";
import { Paths } from "Libraries/Route";
import { Redirect } from "Routes/Redirect";

import LoginScreen from "Screens/Login/Content";
import SignUpScreen from "Screens/SignUp/Content";
import DashboardScreen from "Screens/Dashboard/Content";
import ForgotPasswordScreen from "Screens/ForgotPassword/Content";
import ForgotPasswordConfirmScreen from "Screens/ForgotPasswordConfirm/Content";
import CompanyScreen from "Screens/Company/Content";
import VersionList from "Screens/Dashboard/VersionList";
import UploadFileViaEmail from "Screens/Dashboard/UploadFileViaEmail";
import ReportedOccupancy from 'Screens/Reported/Occupancy';
import ReportedConstruction from 'Screens/Reported/Construction';
import Triage from "Screens/Triage/Triage";
import TemplateHistory from "Screens/TemplateHistory/TemplateHistory";
import Subscriptions from "Screens/Subscriptions/Subscriptions";
import Users from "Screens/Users/Users";
import Invoice from "Screens/Invoice/Invoice";
import Stp from "Screens/Stp/Stp";
import ReportAnalytics from "Screens/Report/ReportAnalytics";
import { useDispatch } from "react-redux";
import ApiRequest from "Services/ApiRequest";
import { setPasswordRulesAction } from "Redux/Action";
import ReportAnalyticsBE from "Screens/Report/ReportAnalyticsBE";

function App(): JSX.Element {

  const dispatch = useDispatch();

  React.useEffect(() => {
    getPasswordRules();
  }, []);

  async function getPasswordRules(): Promise<void> {
    const { data } = await ApiRequest.getPasswordRules();
    dispatch(setPasswordRulesAction(data.password_rules));
  }

  return (
      <Routes>
          <Route path={Paths.main} element={<Redirect to={Paths.login} />} />
          <Route path={Paths.login} element={<LoginScreen />} />
          <Route path={Paths.signup} element={<SignUpScreen />} />
          <Route path={Paths.dashboard}>
            <Route index element={<DashboardScreen />} />
            <Route path={Paths.controlNumber} element={<VersionList />} />
            <Route path={`${Paths.controlNumber}/${Paths.version}/report`}>
              <Route index element={<ReportAnalytics />} />
              <Route path={Paths.systematic} element={<ReportAnalyticsBE />} />
            </Route>
            <Route path={`${Paths.controlNumber}/${Paths.version}/email`} element={<UploadFileViaEmail bottom={2} />} />
          </Route>
          <Route path={Paths.reportedConstruction} element={<ReportedConstruction />} />
          <Route path={Paths.reportedOccupancy} element={<ReportedOccupancy />} />
          <Route path={Paths.templateHistory} element={<TemplateHistory />} />
          <Route path={Paths.triage} element={<Triage />} />
          <Route path={Paths.users} element={<Users />} />
          <Route path={Paths.subscription} element={<Subscriptions />} />
          <Route path={Paths.invoice} element={<Invoice />} />
          <Route path={Paths.stp}>
            <Route index element={<Stp />} />
            <Route path={Paths.controlNumber} element={<Stp />} />
          </Route>
          <Route path={Paths.companies} element={<CompanyScreen />} />
          <Route path={Paths.forgotPassword} element={<ForgotPasswordScreen />} />
          <Route path={Paths.forgotPasswordConfirm} element={<ForgotPasswordConfirmScreen />} />          
      </Routes>
  );
}

export default App;
