import * as React from 'react';

import Modal from 'Components/Modal/Modal';
import RichTextEditor from 'Components/RichText/RichText';
import ApiRequest from 'Services/ApiRequest';
import { IEmailReply, IExposureFile, IPendingSubmissionAttachment, IPendingVersionListItem, IVersionListItem } from 'Libraries/Interfaces';
import { SpinnerLoader } from 'Components/Loader/Loader';
import { toast } from 'react-toastify';
import { Tabs } from 'Components/Tabs/Tabs';
import { MdDelete, MdInsertEmoticon, MdOutlineAttachFile } from 'react-icons/md';
import { FiLink2 } from 'react-icons/fi';
import Methods from 'Libraries/CommonMethodsUI';
import { Attachment, EmailAttachments, EmailItem, EmailReplies, File } from './EmailReplies';

interface IProps {
    open: boolean; 
    setOpen: (f: boolean) => void;
    selectedVersion: IVersionListItem | undefined | any;
    controlNumber: string;
    isPendingSubmissionVersion?: IPendingVersionListItem | undefined;
}

const ExposuresModal: React.FC<IProps> = ({ 
    open, setOpen, controlNumber, selectedVersion, isPendingSubmissionVersion 
}) => {

    const [loading, setLoading] = React.useState<boolean>(true);
    const [showEditor, setShowEditor] = React.useState<boolean>(false);
    const [isSendLoading, setIsSendLoading] = React.useState<boolean>(false);
    const [emailAttachedFiles, setEmailAttachedFiles] = React.useState<IExposureFile[]>([]);
    const [exposureTemplates, setExposureTemplates] = React.useState<{ file_name: string; type: string; original_file_name?: string; }[]>([]);

    const [pendingSubmissionAttachments, setPendingSubmissionAttachments] = React.useState<IPendingSubmissionAttachment[]>([]);

    const [body, setBody] = React.useState<string | undefined>(undefined);
    const [attachments, setAttachments] = React.useState<File[]>([]);
    const [emailReplies, setEmailReplies] = React.useState<IEmailReply[]>([]);

    const [activeTab, setActiveTab] = React.useState<number>(1);

    const fetchAllReplies = React.useCallback(async () => {
        try {
            const res = await ApiRequest.emailReplyList({
                version: selectedVersion?.version, control_number: controlNumber
            });
            setEmailReplies(res.data ?? []);
            setLoading(false);
        }
        catch (err) {
            setEmailReplies([]);
            setLoading(false);
        }
    }, [selectedVersion?.version, controlNumber]);

    const getPendingSubmissionVersionAttachments = React.useCallback(async () => {
        if (open && isPendingSubmissionVersion) {
            setLoading(true);
            try {
                const res = await ApiRequest.getPendingSubmissionVersionAttachments({
                    pending_submission_version_id: isPendingSubmissionVersion?.id, control_number: controlNumber
                });
                setLoading(false);
                setPendingSubmissionAttachments(res.data?.results ?? []);
            }
            catch(e) {
                setLoading(false);
            }
        }
    }, [isPendingSubmissionVersion, controlNumber, open]);

    const initialRender = React.useCallback(async () => {
        const params = { version: selectedVersion?.version, control_number: controlNumber };

        try {
            const result1 = await ApiRequest.getVersionExposureFiles(params);
            const result2 = await ApiRequest.getVersionExposureTemplates(params);
            setEmailAttachedFiles(result1.data);
            setExposureTemplates(result2.data?.response ?? []);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
        }
    }, [selectedVersion?.version, controlNumber]);

    React.useEffect(() => {
        getPendingSubmissionVersionAttachments();
    }, [getPendingSubmissionVersionAttachments]);

    React.useEffect(() => {
        if (open && selectedVersion?.version) {
            setTimeout(() => {
                setShowEditor(true);
            }, 250);
            setLoading(true);
            setEmailAttachedFiles([]);
            setExposureTemplates([]);
            
            initialRender();
            fetchAllReplies();        
        }

        return () => {
            setShowEditor(false);
        }
    }, [open, selectedVersion?.version, controlNumber, fetchAllReplies, initialRender]);

    const selectFiles = (files: FileList | null | any): void => {
        const arr = [];
        for (let i = 0; i < files.length; i++) {
            arr.push(files[i]);
        }
        const all = [ ...arr, ...attachments ];
        const unique = [...new Map(all.map((m) => [m.name, m])).values()];
        setAttachments(unique);
    };

    const send = React.useCallback(async () => {
        if (attachments.length > 0 || (body && body !== "<p></p>\n")) {
            const formData = new FormData();
            formData.append("control_number", controlNumber as any);
            formData.append("version", selectedVersion?.version as any);
            formData.append("message", body as any);
            if (attachments.length > 0) {
                attachments.forEach((file) => {
                    formData.append("file", file);
                });
            }

            setIsSendLoading(true);

            try {
                const res = await ApiRequest.sendUWEmail(formData);
                toast.success(res.data.message);
                setAttachments([]);
                setBody('');
                fetchAllReplies();
                setIsSendLoading(false);                
            }
            catch (e) {
                setIsSendLoading(false);
            }
        } else {
            setIsSendLoading(false);
            toast.error('Type email or select atleast one attachment');
        }
    }, [body, attachments, controlNumber, selectedVersion, fetchAllReplies]);

    return (
        <Modal open={open} setOpen={setOpen} isSmall={false} isMedium={false}>
            <div className='flex flex-col px-5 py-2' id="exposure-modal-top">
                
                <div className='flex flex-row items-center justify-evenly border-b pb-4 mx-3 border-primary-grey'>
                    <Tabs activeClassName="font-semibold text-base w-40 mx-6 border-b-2 text-center border-primary-grey" inActiveClassName="font-normal text-black text-center" onChange={setActiveTab} active={activeTab} tabs={[ { id: 1, name: 'SOV Slip (Email)' }, { id: 2, name: 'Output Files' } ]} />
                </div>

                { activeTab === 2 ? (
                    <div className='flex flex-col mt-5 overflow-y-auto remove-scrollbar h-[480px]'>
                        { loading ? (
                            <SpinnerLoader adjustment={true} enhance="text-black text-sm text-center my-3" />
                        ) : exposureTemplates.length === 0 ? (
                            <span className='text-center text-sm text-primary-grey-10 p-3 block'>No exposure files found.</span>
                        ) : exposureTemplates.map((obj, index) => (
                            <File 
                                key={index} name={obj.type}
                                iconSrc={Methods.getFileIconWithIncludes(obj.file_name ?? obj['original_file_name'])} src={obj.file_name} 
                            />
                        )) }
                    </div>
                ) : activeTab === 1 ? (
                    <div className='flex flex-col mt-5 overflow-y-auto remove-scrollbar max-h-[480px]'>
                        <div className="text-xl text-Black mx-0 mb-6 p-0 px-6">
                            {selectedVersion?.email_subject ?? isPendingSubmissionVersion?.email_subject ?? ''}
                        </div>
                        <EmailItem 
                            attachments={emailAttachedFiles} cc_list={isPendingSubmissionVersion?.cc_list ?? selectedVersion?.cc_list ?? []} created_on={selectedVersion?.created_on ?? isPendingSubmissionVersion?.created_on}
                            email_body={selectedVersion?.email_body ?? isPendingSubmissionVersion?.email_body} 
                            sender={selectedVersion?.sender ?? isPendingSubmissionVersion?.sender}
                            isReply={!isPendingSubmissionVersion}
                        />

                        <EmailAttachments attachments={pendingSubmissionAttachments.map((item) => {
                            return {
                                url: item.file, name: item.original_file_name, systemFileName: item?.system_file_name
                            }
                        })} />

                        { !isPendingSubmissionVersion && (
                            <EmailReplies replies={emailReplies} />
                        )}
                        
                        { showEditor && (
                            <RichTextEditor value={body} setValue={setBody} id="exposure-modal-textfield" />
                        )}                            

                        { attachments.length > 0 && (
                            <div className="flex flex-row items-center justify-start w-full flex-wrap mt-2 px-6">
                                { attachments.map((item: File, index) => (
                                    <Attachment 
                                        key={index} name={item.name} size={item.size} url={undefined}
                                        onClose={() => setAttachments(attachments.filter((_, i) => i !== index))}
                                        systemFileName={item.name}
                                    />
                                )) }
                            </div>
                        )}

                        { !isPendingSubmissionVersion && (
                            <div className='flex items-center justify-end mx-3 z-10 mt-3'>
                                <button disabled={isSendLoading} onClick={() => send()} type="button" className="inline-flex justify-center capitalize items-center text-sm font-semibold rounded-md bg-primary-blue-dark hover:bg-opacity-60 transition-all duration-200 w-20 h-9 text-white shadow-sm">
                                    { isSendLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Send' }
                                </button>
                                <label className='ml-3 relative'>
                                    <span><MdOutlineAttachFile className='cursor-pointer text-2xl text-primary-grey-10 transition-all duration-200 ease-in-out hover:text-black' /></span>
                                    <input multiple placeholder="" title='attachments' type='file' className="hidden" onChange={(e) => selectFiles(e.target.files)} />
                                </label>
                                <FiLink2 className='cursor-pointer text-2xl text-primary-grey-10 ml-3 transition-all duration-200 ease-in-out hover:text-black' />
                                <MdInsertEmoticon className='cursor-pointer text-2xl text-primary-grey-10 ml-3 transition-all duration-200 ease-in-out hover:text-black' />
                                <MdDelete className='ml-3 cursor-pointer text-2xl text-primary-grey-10 transition-all duration-200 ease-in-out hover:text-black' />
                            </div>
                        )}
                        
                    </div>
                ) : null }
            </div>
        </Modal>
    );
}

export default ExposuresModal;