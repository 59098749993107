import React, { useEffect, useMemo, useState } from "react";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Constants from "Libraries/Constants";
import { IMappedDataMatrix, IReportFilter } from "Libraries/Interfaces";
import Methods from "Libraries/CommonMethodsUI";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

interface IBarChart {
  reportTitle: boolean;
  mode: string;
  data: any[];
  className?: string;
  id?: string;
  isUpdation?: number;
  isModifiedData?: boolean;
  onSelection?: (obj: any) => void;
  isCompare?: boolean;
  byLocationOrTiv?: boolean;
  storedFilters?: IReportFilter[];
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip
);

export const useFetchBarChartDataSets = (matrixData: any): { data: { mode: string, dataSet: any; }[]; layoutChange: () => void; } => {
  
  const [results, setResults] = useState<{ mode: string, dataSet: any; }[]>([]);

  const layoutChange = React.useCallback(async () => {

    if (!matrixData || Object.keys(matrixData).length === 0) {
      return;
    }

    const {
      cityMatrix,
      stateMatrix,
      countriesMatrix,
      postalMatrix,
      countyMatrix,
      cTierMatrix,
      huZoneMatrix,
      eqZoneMatrix,
      constructionMatrix,
      occupancyMatrix,
      yearBuiltMatrix,
      storiesMatrix
    } = matrixData;

    const keys: string[] = Object.keys(Constants.barChartModeTypes);
    const arr: any[] = [];
    keys.forEach((key, ind) => {
      let response;
      if (ind === 0) {
        response = huZoneMatrix;
      } else if (ind === 1) {
        response = eqZoneMatrix;
      } else if (ind === 2) {
        response = countriesMatrix;
      } else if (ind === 3) {
        response = stateMatrix;
      } else if (ind === 4) {
        response = cTierMatrix;
      } else if (ind === 5) {
        response = countyMatrix;
      } else if (ind === 6) {
        response = matrixData?.reportedCountyMatrix ?? [];;
      } else if (ind === 7) {
        response = postalMatrix;
      } else if (ind === 8) {
        response = cityMatrix;
      } else if (ind === 9) {
        response = constructionMatrix;
      } else if (ind === 10) {
        response = occupancyMatrix;
      } else if (ind === 11) {
        response = yearBuiltMatrix;
      } else if (ind === 12) {
        response = storiesMatrix;
      } else if (ind === 13) {
        response = matrixData?.geoCodeMatrix ?? [];
      }

      arr.push({ mode: key, dataSet: buildDataset(key, response) });
    });

    setResults(arr);
  }, [matrixData]);

  useEffect(() => {
    layoutChange();
  }, [layoutChange]);

  // function to construct desire dataset from given data array
  const buildDataset = (mode: string, dataArry: IMappedDataMatrix[]) => {
    const modifiedData =
      mode === "yearBuilt"
        ? Methods.yearBuiltDataVisulizationPerform(dataArry)
        : mode === "stories"
        ? Methods.storiesDataVisulizationPerform(dataArry)
        : dataArry;

    const labels = Methods.removeDublicatesInArray(
      modifiedData?.map((item) => item?.label?.toString()?.trim())
    );

    const _data = Methods.modifyMatrixData(labels, modifiedData);

    return {
      labels: labels?.map((l: string) => (l === "" ? `${ ['huZone', 'eqZone'].includes(mode) ? 'Other' : 'Unknown' }` : (l?.includes('County')) ? l?.split('County')?.join('') : l)),
      datasets: [
        {
          label: "Building Limit",
          data: _data?.map((item) => item.cova),
          backgroundColor: "rgba(246, 212, 88, 0.8)",
        },
        {
          label: "Other Limit",
          data: _data?.map((item) => item.covb),
          backgroundColor: "rgba(78, 129, 188, 0.8)",
        },
        {
          label: "Contents Limit",
          data: _data?.map((item) => item.covc),
          backgroundColor: "rgba(9, 145, 205, 0.8)",
        },
        {
          label: "BI Limit",
          data: _data?.map((item) => item.covd),
          backgroundColor: "rgba(239, 80, 23, 0.8)",
        },
      ],
    };
  };

  return React.useMemo(() => {
    return { data: results, layoutChange };
  }, [results, layoutChange]);
};

export const useFetchBarChartDataSetsForReport = (selectedGeographies: string[], matrixData: any) => {
  const { data } = useFetchBarChartDataSets(matrixData);
  const filtered = data?.filter((f) => selectedGeographies.includes(Constants.barChartModeTypes[f.mode]));

  return React.useMemo(() => {
    const arr: any[] = [];
    filtered.forEach((v) => {
      const slice: { start: number; end: number }[] = [];
      for (let i = 0; i < Math.ceil(v.dataSet?.labels?.length / 15); i++) {
        if (i === 0) {
          arr.push({ title: `TIV by ${Constants.barChartModeTypes[v.mode]}`, mode: v.mode, labels: v.dataSet.labels.slice(i, 15), dataSet: v.dataSet.datasets.map((set: any) => {
            return { ...set, data: set.data.slice(i, 15) };
          }) });
          slice.push({ start: i, end: 15 });
        } else {
          const start: number = i * 15;
          const end: number = start + 15;
          arr.push({ title: `TIV by ${Constants.barChartModeTypes[v.mode]}`, mode: v.mode, labels: v.dataSet.labels.slice(start, end), dataSet: v.dataSet.datasets.map((set: any) => {
            return { ...set, data: set.data.slice(start, end) };
          }) });
        }
      }
    });

    const results = [];
    for (let i = 0; i < arr.length; i += 2) {
      results.push(arr[i + 1] ? [arr[i], arr[i + 1]] : [arr[i]]);
    }
    return results;
  }, [filtered]);
}

const BarChart: React.FC<IBarChart> = ({ reportTitle, mode = 'state', data }) => {  
  const results = data.find((f) => f.mode === mode)?.dataSet;

  return useMemo(() => {

    const options = {
      responsive: true,
      plugins: {
        colors: {
          enabled: false,
          forceOverride: true
        },
        legend: {
          display: true,
          labels: { font: { size: 12 } }
        },
        title: {
          display: false,
          text: `TIV by ${Constants.barChartModeTypes[mode]}`,
          font: {
            size: reportTitle ? 19 : 12,
          },
          color: '#000'
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            // For a category axis, the val is the index so the lookup via getLabelForValue is needed
            callback: function (this: any, val: string) {
              return this.getLabelForValue(val).length > 12
                ? this.getLabelForValue(val).slice(0, 12) + " ..."
                : this.getLabelForValue(val);
            } as any,
            color: "black",
          },
        },
        y: {
          stacked: true,
        },
      },
      interaction: {
        mode: "index" as const,
        intersect: true,
      },
      tooltips: {
        mode: "nearest",
        intersect: true,
      },
    };

    const slice: { start: number; end: number }[] = [];
    for (let i = 0; i < Math.ceil(results?.labels?.length / 15); i++) {
      if (i === 0) {
        slice.push({ start: i, end: 15 });
      } else {
        slice.push({
          start: slice[i - 1].end,
          end: slice[i - 1].end + 15,
        });
      }
    }

    return (
        <div className="bar-charts">
            <h4 className="mb-3 text-black underline text-xs text-center block w-100 font-semibold">
              TIV by {Constants.barChartModeTypes[mode]}
            </h4>

            {slice.map((item, index) => (
            <Bar
                options={{ ...options, maintainAspectRatio: true }}
                data={{
                  datasets: results?.datasets.map((set: any) => {
                      return {
                      ...set,
                      data: set.data.slice(item.start, item.end),
                      barThickness: 14, maxBarThickness: 18, borderRadius: 4
                    };
                  }),
                  labels: results?.labels.slice(item.start, item.end),
                }}
                key={index} width={250} height={90}
            />
            ))}
        </div>
    );
  }, [mode, results, reportTitle]);
};

export const CustomBarChart = ({ mode, labels, isTitle, datasets, height }: { mode: string; labels: string[]; isTitle: boolean; datasets: any, height: number; }) => {
  const options = {
    responsive: true,
    plugins: {
      colors: {
        enabled: false,
        forceOverride: true
      },
      legend: {
        display: true,
        labels: { font: { size: 20 } }
      },
      title: {
        display: false,
        text: `TIV by ${Constants.barChartModeTypes[mode]}`,
        font: {
          size: isTitle ? 19 : 14,
        },
        color: "#000"
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback: function (this: any, val: string) {
            return this.getLabelForValue(val).length > 12
              ? this.getLabelForValue(val).slice(0, 12) + " ..."
              : this.getLabelForValue(val);
          } as any,
          color: "black",
        },
      },
      y: {
        stacked: true,
      },
    },
    interaction: {
      mode: "index" as const,
      intersect: true,
    },
    tooltips: {
      mode: "nearest",
      intersect: true,
    },
  };

  return (
    <Bar
      options={{ ...options, maintainAspectRatio: true }}
      data={{
        datasets: datasets,
        labels: labels,
      }}
      width={500} height={ height ? height : 250 }
      style={{ overflowX: "auto" }}
    />
  );
}

export const AMGeographyBarChart: React.FC<IBarChart> = ({ 
  reportTitle, mode, className, id, data, isUpdation, isModifiedData, onSelection, isCompare, byLocationOrTiv, storedFilters
}) => {  
  
  const getUpdatedData = React.useCallback(() => {
    const find = data?.find((f) => f?.mode === mode);

    if (find && find?.dataSet && isModifiedData) {
      const { datasets: sets, labels } = Methods.unKnownValueSwap(find?.dataSet);

      const getSetsValue = (ind: number): number => {
        return Methods.arrayValuesSum(sets[ind].data?.slice(9));
      }
  
      const modifiedData = labels?.length > 10 ? [...labels?.slice(0, 9)?.map((l: string, ind: number) => {
        return { 
          category: l, 
          value: sets[0].data[ind] + sets[1].data[ind] + sets[2].data[ind] + sets[3].data[ind], // This is total coverage of perticular row
          building: sets[0].data[ind], other: sets[1].data[ind],
          contents: sets[2].data[ind], bi: sets[3].data[ind],
        };
      }) , { 
        category: "All Others",
        value: getSetsValue(0) + getSetsValue(1) + getSetsValue(2) + getSetsValue(3), // This is total coverage of perticular row
        building: getSetsValue(0), other: getSetsValue(1),
        contents: getSetsValue(2), bi: getSetsValue(3),
      }] : 
      labels?.map((l: string, ind: number) => {
        return { 
          category: l, 
          value: sets[0].data[ind] + sets[1].data[ind] + sets[2].data[ind] + sets[3].data[ind], // This is total coverage of perticular row
          building: sets[0].data[ind], other: sets[1].data[ind],
          contents: sets[2].data[ind], bi: sets[3].data[ind],
        };
      }); 

      return { data: modifiedData, max: Math.max(...modifiedData.map((v: any) => v?.value)) };
    } 
    else if (!isModifiedData) {
      const arr = data?.map((v) => {
        return { 
          category: v?.data_by, value: v?.tiv, building: v?.building, other: v?.other, contents: v?.contents, bi: v?.bi,
          data_by_list: v?.data_by_list, location_count: v?.location_count
        };
      });
      return { data: arr, max: byLocationOrTiv ? Math.max(...arr.map((v: any) => v?.value)) : Math.max(...arr.map((v: any) => v?.location_count)) };
    }
    else {
      return { data: [], max: 0 };
    }
  }, [data, mode, isModifiedData, byLocationOrTiv]);

  const colorsUpdate = React.useCallback(() => {
    const selectedCategories: string[] = storedFilters?.map(v => v.title) ?? [];
    let root = am5.getRootById(id as string);

    if (root && selectedCategories.length > 0) {
      let chart = root.container.children.values.find((child: any) => child instanceof am5xy.XYChart);
      if (chart) {
        chart.series.each((s: any) => {
          const name = s.get('name');
          const finder = Constants.barChartSeriesColors.find(f => f.label === name);
          s.columns.each((column: any) => {
            if (!selectedCategories.includes(column?.dataItem?.dataContext.category)) {
              column.set("fill", am5.Color.fromString("#f1f1f1")); // Grey color
            }
            else {
              column.set("fill", am5.Color.fromAny(finder?.color ?? "rgba(246, 212, 88, 0.8)"));
            }
          });
        });
      }
    }
  }, [storedFilters, id]);

  React.useEffect(() => {
    colorsUpdate();
  }, [colorsUpdate]);

  React.useLayoutEffect(() => {
    if (!document.getElementById(id as any)) {
      return;
    }
  
    let root = am5.Root.new(id as any);
  
    root.setThemes([am5themes_Animated.new(root)]);
  
    let chart: am5xy.XYChart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true,
      pinchZoomY: true,
      layout: root.verticalLayout,
      paddingLeft: 0, marginTop: 0, marginBottom: 0
    }));
  
    let modifiedData: any[] = getUpdatedData()?.data;
    let maxx: number = getUpdatedData()?.max as number;
  
    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minGridDistance: 30,
      minorGridEnabled: true
    });
  
    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1
    });
  
    yRenderer.labels.template.setAll({
      fontSize: 10
    });
  
    xRenderer.labels.template.setAll({
      rotation: 0,
      maxWidth: isCompare ? (modifiedData.length > 5 ? 50 : 70) : 70,
      fontSize: 10,
      oversizedBehavior: isCompare ? (modifiedData.length > 5 ? "truncate" : "wrap") : "wrap",
      textAlign: "center",
      interactive: true
    });
  
    let categoryAxis = am5xy.CategoryAxis.new(root, {
      categoryField: "category",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {}),
      interactive: true
    });
  
    let xAxis = chart.xAxes.push(categoryAxis);
  
    xRenderer.grid.template.setAll({
      location: 1
    });
  
    xAxis.data.setAll(modifiedData);

    xAxis.get("renderer").labels.template.setup = (target: any) => {
      target.setAll({
        cursorOverStyle: "pointer",
        background: am5.Rectangle.new(root, {
          fill: am5.color(0x000000),
          fillOpacity: 0
        })
      });
    };
    
    xAxis.get("renderer").labels.template.events.on("click", (ev: any) => {
      if (onSelection) {
        onSelection(ev.target.dataItem?.dataContext);
      }
    });
  
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      calculateTotals: true,
      extraMax: 0.05,
      renderer: yRenderer,
      max: maxx < 5 ? 5 : maxx,
      min: 0,
    }));
 
    // Format Y-axis labels
    yAxis.get("renderer").labels.template.adapters.add("text", (text: any, target: any) => {
      return !byLocationOrTiv ? (text?.includes(',') ? Methods.numberFormatter(Number(text.replace(/,/g, ''))) : parseInt(text)?.toString()) 
      : (text ? `$ ${Methods.numberFormatter(Number(text?.split(',').join('')))}` : '');
    });
  
    // Add series
    function makeSeries(name: string, fieldName: string, color: string) {
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: fieldName,
        categoryXField: "category",
        clustered: true
      }));
  
      series.columns.template.setAll({
        tooltipText: "{categoryX} - {name}: $ {valueY.formatNumber('#a')}", 
        cornerRadiusTL: 1, cornerRadiusTR: 1, 
        strokeOpacity: 0, maxWidth: 18,
        tooltipY: 0,
        fill: am5.Color.fromAny(color),
        stroke: am5.Color.fromAny(color)
      });
  
      series.data.setAll(modifiedData);
  
      series.columns.template.adapters.add("tooltipText", (text: any, target: any) => {
        const dataItem = target.dataItem;
        if (dataItem) {
          const valueY = dataItem.get("valueY");
          const value = dataItem.dataContext.value;
  
          return !byLocationOrTiv ? `[fontSize: 12px]{name}: ${valueY}[/]` : `[fontSize: 12px]{name}: ${Methods.numberFormatter(valueY, 2)}[/]\n[fontSize: 12px]Total TIV: ${Methods.numberFormatter(value, 2)}`;
        }
        return text;
      });
  
      // Assuming you already have your chart and series setup:
      series.columns.template.events.on("pointerover", function (event: any) {
        const column = event.target;
        
        // Change colors of all segments in the stack
        chart.series.each((s: any) => {
          s.columns.each((col: any) => {
            col.set("fillOpacity", col === column ? 0.5 : 1); // Set opacity of hovered stack to full, others to lower
          });
        });
      });
  
      series.columns.template.events.on("pointerout", function (event: any) {
        // Reset the colors when mouse is out
        chart.series.each((s: any) => {
          s.columns.each((col: any) => {
            col.set("fillOpacity", 1); // Reset opacity to original
          });
        });
      });
  
      series.columns.template.events.on("click", function(ev: any) {  
        if (onSelection) {
          onSelection(ev.target.dataItem?.dataContext);
        }
      });
  
      // Make stuff animate on load
      series.appear();
  
      series.bullets.push(function() {
        let label = am5.Label.new(root, {
          text: "{valueY}",
          fill: root.interfaceColors.get("alternativeText"),
          centerY: am5.p50,
          centerX: am5.p50,
          populateText: true,
          textAlign: "center",
          oversizedBehavior: "hide"
        });
  
        label.adapters.add("text", function(text, target: any) {
          let val = Math.abs(target.dataItem.get("valueY") - target.dataItem.get("openValueY"));
          return val as any;
        });
  
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 0.5,
          sprite: label
        });
      });
    }
  
    Constants.barChartSeriesColors.slice(byLocationOrTiv ? 1 : 0, byLocationOrTiv ? 5 : 1).forEach(v => {
      makeSeries(v.label, v.identify, v.color);
    });
  
    // Make stuff animate on load
    chart.appear(1000, 100);
  
    return () => {
      root.dispose();
    };
  }, [getUpdatedData, reportTitle, id, isUpdation, isCompare, byLocationOrTiv]);
  
  return (
    <div id={id} className={`relative ${className}`} style={{ width: "100%" }}>
      <div className="absolute bg-white z-10 w-[66px] h-[19px] left-0 bottom-0 rounded-2xl"></div>
    </div>
  );
};

export const AMGeoConfidenceBarChart: React.FC<{ id: string; className: string; data: { confidence: string; count: number; }[] }> = ({ id, data, className }) => {  

  React.useLayoutEffect(() => {

    if (!document.getElementById(id as any)) {
      return;
    }

    let root = am5.Root.new(id as any);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      layout: root.verticalLayout,
      paddingLeft: 0,
      marginTop: 0,
      marginBottom: 0
    }));

    let modifiedData: { [key: string]: number | any; } = {};
    for (let ind = 0; ind < data.length; ind++) {
      const currentOpen = ind === 0 ? 0 : modifiedData[`close${(ind + 1) - 1}`];
      const currentClose = ind === 0 ? data[ind].count : data[ind].count + currentOpen;

      modifiedData = { 
        ...modifiedData, [`open${ind + 1}`]: currentOpen, [`close${ind + 1}`]: currentClose
      };
    }
            
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minGridDistance: 30,
      minorGridEnabled: true
    });

    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1
    });

    yRenderer.labels.template.setAll({
      fontSize: 10
    });

    xRenderer.labels.template.setAll({
      rotation: 0,
      maxWidth: 160,
      fontSize: 10,
      oversizedBehavior: "wrap",
      textAlign: "center"
    });
    
    let categoryAxis = am5xy.CategoryAxis.new(root, {
      categoryField: "category",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    });
    let xAxis = chart.xAxes.push(categoryAxis);
    
    xRenderer.grid.template.setAll({
      location: 1
    });

    xAxis.data.setAll([{ ...modifiedData, category: "Geocode Confidence" }]);
    
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      calculateTotals: true,
      extraMax: 0.05,
      renderer: yRenderer,
      max: Methods.arrayValuesSum(data.map(v => v.count)),
    }));

    yAxis.get("renderer").labels.template.adapters.add("text", (text: any, target: any) => {
      return '';
    });

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name: string, field: string, openField: string, total: any, color: string, count: number) {
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: field,
        openValueYField: openField,
        categoryXField: "category",
        clustered: false
      }));
    
      series.columns.template.setAll({
        tooltipText: `{name}: ${count}`,
        // cornerRadiusTL: 5, cornerRadiusTR: 5, 
        strokeOpacity: 0, maxWidth: 18,
        tooltipY: 0,
        fill: am5.Color.fromString(color),
        stroke: am5.Color.fromString(color)
      });

      series.data.setAll([{ ...modifiedData, category: "Geocode Confidence", }]);
      series.columns.template.set("tooltip", am5.Tooltip.new(root, {
        labelText: `[fontSize: 12px]{name}: ${count}[/]`
      }));

      // Make stuff animate on load
      series.appear();
    
      series.bullets.push(function() {
        let label = am5.Label.new(root, {
          text: "{valueY}",
          fill: root.interfaceColors.get("alternativeText"),
          centerY: am5.p50,
          centerX: am5.p50,
          populateText: true,
          textAlign: "center",
          oversizedBehavior: "hide"
        });
    
        label.adapters.add("text", function(text, target: any) {
          let val = Math.abs(target.dataItem.get("valueY") - target.dataItem.get("openValueY"));
          return val as any;
        });
    
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 0.5,
          sprite: label
        });
      });
    
      if (total) {
        series.bullets.push(function() {
          let totalLabel = am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("text"),
            centerY: am5.p100,
            centerX: am5.p50,
            populateText: true,
            textAlign: "center"
          });
    
          totalLabel.adapters.add("text", function(text, target: any) {
            let dataContext = target.dataItem.dataContext;
            let val = Math.abs(dataContext.close2 - dataContext.open1);
            return val as any;
          });
    
          return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 1,
            sprite: totalLabel
          });
        });
      }
    }
    
    Constants.geocodeConfidences.filter(f => data.map(d => d.confidence.toLowerCase()).includes(f.type.toLowerCase())).map((v, ind) => {
      makeSeries(v.type, `close${ind + 1}`, `open${ind + 1}`, false, v.color, data.find(f => f.confidence.toLowerCase() === v.type.toLowerCase())?.count ?? 0);
      return null;
    });

    // Make stuff animate on load
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };

  }, [data, id]);

  return (
    <div id={id} className={`relative ${className}`} style={{ width: "100%" }}>
      {/* <div className="absolute bg-white z-10 w-[66px] h-[19px] left-0 bottom-0 rounded-2xl"></div> */}
    </div>
  );
};

export default BarChart;
