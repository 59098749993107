import * as React from 'react';

import { Switch } from '@headlessui/react';

interface IProps {
    enabled: boolean; 
    setEnabled: (f: boolean) => void;
    title: string;
    isTwiceTitle?: string;
}

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ')
}

export default function SwitchToggle({ enabled, setEnabled, title }: IProps) {
  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={classNames(
          enabled ? 'bg-white' : 'bg-primary-grey-20 bg-opacity-90',
          'relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5 bg-primary-blue' : 'translate-x-0 bg-primary-grey-20 bg-opacity-40',
            'pointer-events-none inline-block h-4 w-4 transform rounded-full shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-4 text-xs text-white">
        <span className="font-normal">{title}</span>
      </Switch.Label>
    </Switch.Group>
  )
}

export function SwitchShortToggle({ enabled, setEnabled, title, isTwiceTitle }: IProps) {
  return (
    <Switch.Group as="div" className="flex items-center pl-2">
      { isTwiceTitle && (
        <Switch.Label as="span" className="text-xs mr-2 text-black">
          <span className="font-normal">{isTwiceTitle}</span>
        </Switch.Label>
      )}
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={classNames(
          enabled ? 'bg-gray-200' : 'bg-gray-200 bg-opacity-90',
          'hover:bg-opacity-40 relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-1 focus:ring-primary-green focus:ring-offset-1'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5 bg-primary-green' : `${isTwiceTitle ? 'bg-primary-green' : 'bg-primary-grey-20 bg-opacity-40'} translate-x-0`,
            'hover:bg-opacity-40 pointer-events-none inline-block h-4 w-4 transform rounded-full shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      <Switch.Label as="span" className="text-xs ml-2 text-black">
        <span className="font-normal">{title}</span>
      </Switch.Label>
    </Switch.Group>
  )
}