import * as React from 'react';

import { SpinnerLoader } from 'Components/Loader/Loader';
import Methods from 'Libraries/CommonMethodsUI';
import { IHistoryItem } from 'Libraries/Interfaces';
import ApiRequest from 'Services/ApiRequest';

interface IProps {
    versionId: string | undefined;
    controlNumber: string;
}

const HistoryItem: React.FC<{ item: IHistoryItem }> = ({ item }) => {
    return (
        <div className="flex flex-col justify-start mb-7">
            <div className="flex flex-row items-center justify-start text-xs mb-2">
                <span className='font-bold text-black'>"{item.status.split('_').join(' ')}"</span>
                <span className='text-primary-dark opacity-50 italic'>({ item?.log_by_name ?? '' })</span>
            </div>
            <span className="text-black pl-1 text-xs">{Methods.convertUTCtoLocalDate(item.created_on)}</span>
        </div>
    );
}

export const HistoryTab: React.FC<IProps> = ({ controlNumber, versionId }) => {

    const [loading, setLoading] = React.useState<boolean>(true);
    const [histories, setHistories] = React.useState<IHistoryItem[]>([]);

    const get = React.useCallback(async (flag: boolean) => {
        setLoading(flag);

        try {
            const res = await ApiRequest.getVersionHistories({
                version: versionId, control_number: controlNumber
            });
            setHistories(res.data.results);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);            
        }
    }, [versionId, controlNumber]);

    React.useEffect(() => {
        if(versionId) {
            get(true);
        }
    }, [versionId, get]);

    return (
        <div className="flex flex-col justify-start border-b-2 border-primary-grey">            
            { loading ? (
                <SpinnerLoader adjustment={true} enhance="text-black text-sm my-3" />
            ) : histories.length === 0 ? (
                <span className='text-sm text-black text-center mb-4'>No history available.</span>
            ) : histories.map((item: IHistoryItem, i: number) => (
                <HistoryItem key={i} item={item} />
            )) }
        </div>
    );
}
