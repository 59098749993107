import React, { useState } from 'react';

import withAuthentication from "Hoc/WithAuthentication";
import { useLocation } from 'react-router-dom';

import Modal, { DeleteOrAddPremiumModal } from "Components/Modal/Modal";
import RowClickModal from "Components/RowClickModal/RowClickModal";
import PaginatedRecord from "Components/PaginatedRecord/PaginatedRecord";
import { Paths } from "Libraries/Route";
import {Popup} from "Components/Popup/Popup";
import {Attachments} from "Components/Attachments/Attachments";
import MasterLayout from 'Hoc/MasterLayout';
import DocumentTitle from 'Components/DocumentTitle/DocumentTitle';
import MainCards from 'Components/MainCards/MainCards';
import { ISelectionRange } from 'Components/DateRange/DateRange';
import ApiRequest from 'Services/ApiRequest';
import { ISubmissionListItem, IUserProfile } from 'Libraries/Interfaces';
import Methods from 'Libraries/CommonMethodsUI';
import { CustomTable } from 'Components/CustomTable/CustomTable';
import { DashboardFilter } from 'Components/ScreenFilters/ScreenFilters';
import { MdDelete } from 'react-icons/md';
import CustomCheckBox from 'Components/Checkbox/Checkbox';
import { toast } from 'react-toastify';
import { RolesEnum, statusEnum } from 'Libraries/Enums';
import { useDebounce } from 'Hooks/UseDebounce';
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

interface IListingProps { 
    submissionList: ISubmissionListItem[]; loading: boolean; page: number; search: string; filters: { [key: string]: string; };
    selected: ISubmissionListItem | undefined; selectionRange: ISelectionRange;
    setSelected: (cn: ISubmissionListItem | undefined) => void; 
    onChangeSorting: (obj: { columnMatch: string; sort: string; }) => void; isDeleteControlNumber: JSX.Element | null;
    sorting: { columnMatch: string; sort: string; }[]; goToVersions: (cn: string, state: { [key: string]: any; }) => void;
    user: IUserProfile;
}

const DashboardScreen = ({ user, navigate }: any) => {

    const { search: searchParams } = useLocation();
    const urlQueryParams: { [key: string]: string; } | null = Methods.parseQueryString(searchParams);

    const [attachmentModal, setAttachmentModal] = useState<{ data: any, flag: boolean | undefined, isExposure: boolean | undefined }>({data: null, flag: false, isExposure: false});
    const [openRowModal, setOpenRowModal] = useState<{ data: any, flag: boolean | undefined, isStatusLog: boolean | undefined }>({data: null, flag: false, isStatusLog: false});
    const [currentPage, setCurrentPage] = React.useState<number>((urlQueryParams && urlQueryParams?.page) ? parseInt(urlQueryParams?.page) : 1);
    const [total, setTotal] = React.useState<number>(1);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [search, setSearch] = React.useState<string>((urlQueryParams && urlQueryParams?.search_by) ? urlQueryParams?.search_by : "");
    const [isAssigned, setIsAssigned] = useState<{ data: string, icon: string, flag: boolean | undefined }>({data: '', icon: '', flag: false});
    const [selectionRange, setSelectionRange] = React.useState<ISelectionRange>({
        startDate: (urlQueryParams && urlQueryParams?.created_on_after) ? new Date(urlQueryParams?.created_on_after) : undefined, 
        endDate: (urlQueryParams && urlQueryParams?.created_on_before) ? new Date(urlQueryParams?.created_on_before) : undefined, 
        key: 'selection',
    });
    const [sorting, setSorting] = React.useState<{ columnMatch: string; sort: string; }[]>([]);
    const [filters, setFilters] = React.useState<{ [key: string]: string; }>({ 
        status: (urlQueryParams && urlQueryParams?.status) ? statusEnum[parseInt(urlQueryParams?.status) - 1] : "", 
        company: (urlQueryParams && urlQueryParams?.company_name) ? urlQueryParams?.company_name : ""
    });
    const [submissionList, setSubmissionList] = React.useState<{ copy: ISubmissionListItem[], original: ISubmissionListItem[] }>({ copy: [], original: [] });
    const [selected, setSelected] = React.useState<ISubmissionListItem | undefined>(undefined);

    const searchQuery = useDebounce(search, 1000);

    const prepareParams = React.useCallback((pg: number): { byObject: { [key: string]: any; }; byString: string; } => {
        let additionalParams: string = `?page_size=10&page=${pg}`;
        const additionalParams1: { [key: string]: any; } = { page_size: 10, page: pg };

        if (selectionRange.startDate && selectionRange.endDate) {
            const startDate = Methods.getDateByFormat(selectionRange?.startDate as any, 'YYYY-MM-DD');
            const endDate = Methods.getDateByFormat(selectionRange?.endDate as any, 'YYYY-MM-DD');

            additionalParams = additionalParams + `&created_on_after=${startDate}&created_on_before=${endDate}`;
            additionalParams1['created_on_after'] = startDate;
            additionalParams1['created_on_before'] = endDate;
        }

        if (searchQuery) {
            additionalParams = additionalParams + `&search_by=${searchQuery}`;
            additionalParams1['search_by'] = searchQuery;
        }
        if (filters.status) {
            const s: string = filters.status.split(" ").join("_") as any;
            additionalParams = additionalParams + `&status=${statusEnum[s as any] + 1}`; // Plus 1 is added for upgrade array index by 1
            additionalParams1['status'] = statusEnum[s as any] + 1;
        }
        if (filters.company) {
            additionalParams = additionalParams + `&company_name=${filters.company}`;
            additionalParams1['company_name'] = filters.company;
        }

        if (sorting.length > 0) {
            additionalParams = additionalParams + `&ordering=${sorting.map((s) => s.sort).toString()}`;
            additionalParams1['ordering'] = sorting.map((s) => s.sort).toString();
        }

        return { byString: additionalParams, byObject: additionalParams1 };
    }, [filters, selectionRange, sorting, searchQuery]);

    const fetchRecords = React.useCallback(async (pg: number) => {
        localStorage.removeItem("report-filters");
        setLoading(true);
        
        const { byObject, byString } = prepareParams(pg);
        const res = await ApiRequest.getSubmissionRequest(undefined, byObject);
        setTotal(res.data?.count);
        setSubmissionList({ copy: res.data?.results, original: res.data?.results });
        setLoading(false);
        setCurrentPage(pg);

        navigate(Paths.dashboard + byString);
    }, [prepareParams, navigate]);

    React.useEffect(() => {
        fetchRecords(currentPage);
    }, [fetchRecords]);

    const onArchiveSubmissionItem = React.useCallback(async () => {
        try {
            await ApiRequest.archiveSubmission({ control_number: selected?.control_number as string });
            const list: ISubmissionListItem[] = submissionList.original.filter((f) => f.control_number !== selected?.control_number);
            setTotal(total - 1);
            setSubmissionList({ copy: list, original: list });
            setSelected(undefined);
            toast.success(`Submission item ${selected?.control_number} is successfully archived.`);
        }
        catch (err) {}
    }, [selected, submissionList.original, total]);

    return (
        <MasterLayout mainTitle="Submissions" navigate={undefined} className='' mainClassName="overflow-hidden" userEmail={user?.email as string}>
            <DocumentTitle title='Submissions'>
                <MainCards className="px-4 sm:px-6 lg:px-8 flow-root" params={sorting.length > 0 ? (
                    Object.fromEntries(Object.entries(prepareParams(currentPage).byObject).filter(([key, _]) => key !== 'ordering'))
                ) : prepareParams(currentPage).byObject} />
                <DashboardFilter 
                    selectionRange={selectionRange} setSelectionRange={setSelectionRange} filters={filters}
                    onChangeSelection={(obj) => {
                        if (obj?.company) {
                            navigate(`?company=${obj?.company}`);
                        }
                        setFilters({ ...filters, ...obj });
                        setCurrentPage(1);
                    }} 
                    search={search} setSearch={(str) => {
                        setSearch(str);
                        if (currentPage !== 1) {
                            setTimeout(() => {
                                setCurrentPage(1);
                            }, 1000);
                        }
                    }} 
                    onEnterPress={() => fetchRecords(1)}
                    onReset={() => {
                        const resets = Object.entries(filters).reduce(function (previous, [key, _]) {
                            return { ...previous, [key]: "" }
                        }, {});
                        setFilters(resets);
                        setSelectionRange({ startDate: undefined, endDate: undefined, key: 'selection' });
                        setSearch("");
                        setSorting([]);
                        setCurrentPage(1);
                        navigate(Paths.dashboard);
                    }}
                />
                
                <div className='h-7'></div>
                <Listing 
                    search={search} selected={selected} setSelected={(cn) => {
                        setSelected(cn?.control_number === selected?.control_number ? undefined : cn);
                    }} submissionList={submissionList.original} loading={loading} page={currentPage} 
                    filters={filters} selectionRange={selectionRange}
                    onChangeSorting={(obj) => {
                        const finder = sorting.find((f) => f.columnMatch === obj.columnMatch);
                        if (finder) {
                            if (finder.sort.includes('-')) {
                                setSorting(sorting.filter((f) => f.columnMatch !== obj.columnMatch));
                            } else {
                                setSorting(sorting.map((v) => {
                                    return v.columnMatch === obj.columnMatch ? { columnMatch: obj.columnMatch, sort: '-'+obj.sort } : {...v};
                                }));
                            }
                        }
                        else {
                            setSorting([ ...sorting, obj ]);
                        }
                    }} sorting={sorting}
                    isDeleteControlNumber={ !selected ? null :
                        <DeleteOrAddPremiumModal status={{ id: 0, value: '' }} onCloseModal={() => console.log()} onConfirm={() => onArchiveSubmissionItem()}>
                            <MdDelete className='cursor-pointer transition-all duration-200 ease-in-out hover:text-black text-2xl text-primary-grey-10' />
                        </DeleteOrAddPremiumModal>
                    }
                    goToVersions={(cn, st) => navigate(`${cn}?${prepareParams(currentPage).byString.slice(1)}`, { state: st })} user={user}
                />

                <PaginatedRecord
                    totalPages={Math.ceil(total / 10)} total={total} listLength={submissionList.original.length}
                    onPageChange={(p) => {
                        fetchRecords(p);
                        navigate(Paths.dashboard, { state: { page: p } });
                    }} currentPage={currentPage} perPage={10}
                    onDisplayChange={(n: number) => setSubmissionList({ ...submissionList, original: submissionList.copy.slice(0, n) })}
                />
            
                {openRowModal.flag &&
                <Modal  open={openRowModal.flag} setOpen={(e: boolean)=> setOpenRowModal({...openRowModal , flag: e })}  isSmall={false}>
                    <RowClickModal openRowModal={openRowModal.data}
                        isStatusLog={openRowModal.isStatusLog} screen={'dashboard'}
                        setOpen={(e:boolean | undefined)=> setOpenRowModal({  ...openRowModal,flag: e }) } 
                    />
                </Modal>
                }

                {isAssigned.flag &&
                    <Modal open={isAssigned.flag } setOpen={(e: boolean) => setIsAssigned({...isAssigned , flag: e })}  isSmall={true}>
                        <Popup message={isAssigned.data} icon={isAssigned.icon} />
                    </Modal>
                }

                {attachmentModal.flag &&
                <Modal open={attachmentModal.flag} setOpen={(e: boolean) => setAttachmentModal({...attachmentModal, flag: e})}  isSmall={true}>
                    <Attachments arr={attachmentModal.data} isExposure={attachmentModal.isExposure} />
                </Modal>
                }                
            </DocumentTitle>
        </MasterLayout>
    )
};

const Listing: React.FC<IListingProps> = ({ 
    submissionList, loading, page, selected, setSelected, search, filters, selectionRange, onChangeSorting, isDeleteControlNumber, 
    sorting, goToVersions, user
}) => {
    const headers: string[] = ['Control Number', 'Name Insured', 'Status', 'Completed On', 'Submitted On', 'Processed In'];

    const onSorting = (head: string, index: number, isIcon: boolean) => {
        onChangeSorting({ columnMatch: head, sort: index === 3 ? 'created_on' : head.toLowerCase().split(' ').join('_') });
    };

    return (
        <div className={`flow-root px-4 sm:px-6 lg:px-8 max-h-[75vh]`}>
            <CustomTable 
                maxHeight="max-h-[75vh]" loading={loading} emptyList={submissionList.length === 0}
                headers={{ data: ['', ...headers], hasIcons: false, modified: (
                    <tr className='border-b-2 border-primary-grey'>
                        <th scope="col" className="pt-1 text-left text-sm font-bold sticky top-0 text-[#343A40] pl-3 w-14 backdrop-blur backdrop-filter">
                            {isDeleteControlNumber}
                        </th>
                        { headers.map((head: string, index) => {
                            const sortingFind = sorting.find((f) => f.columnMatch === head);
                            return (
                                <th 
                                    key={index} className={`py-3 ${index === 0 ? 'min-w-[160px] w-[160px] pl-3' : index === 1 ? 'w-[320px]' : 'w-1/7'} cursor-pointer relative text-left text-sm font-bold text-[#343A40] backdrop-blur backdrop-filter`}
                                    onClick={() => onSorting(head, index, false)}
                                >
                                    {head} {[0, 1, 3].includes(index) && (
                                        <span className='absolute ml-2 top-2'>
                                            <TiArrowSortedUp className="m-0 p-0 translate-y-1" size={18} color={sortingFind ? (sortingFind?.sort?.includes('-') ? '#A9A9A9' : '#000') : '#A9A9A9'} />
                                            <TiArrowSortedDown className="m-0 p-0 -translate-y-1" size={18} color={sortingFind ? (sortingFind?.sort?.includes('-') ? '#000' : '#A9A9A9') : '#A9A9A9'} />
                                        </span>
                                    )}
                                </th>
                            )
                        }) }
                    </tr>
                ) }}
            >
                {submissionList.map((item: ISubmissionListItem, ind: number) => (
                    <tr className={`hover:bg-[#FFEB8C] ${item?.has_unread_email && 'bg-gray-200'} transition-all duration-200 border-b border-primary-grey cursor-pointer`}
                        key={item.id}
                        onClick={() => goToVersions(item.control_number, { nameInsured: item.name_insured, page, search, ...filters, selectionRange })}
                    >
                        <td className="whitespace-nowrap pt-2 px-3 w-14" onClick={(e) => e.stopPropagation()}>
                            <CustomCheckBox checked={selected?.control_number === item.control_number} setChecked={(f: boolean) => {
                                if (user.role === RolesEnum[RolesEnum.CAT_MODELER]) {
                                    setSelected(item);
                                }
                            }} forHead={false} />
                        </td>
                        <td className="whitespace-nowrap py-3 pl-3 min-w-[160px] w-[160px] underline font-bold text-xs text-[#155D9C]">
                            {item.control_number}
                        </td>
                        <td className="whitespace-nowrap py-3 pr-3 w-[320px] text-xs text-primary-grey-10 overflow-hidden text-ellipsis truncate inline-block">{item.name_insured}</td>
                        <td className={`whitespace-nowrap py-3 pr-3 w-1/7 text-xs font-medium 
                         ${item?.status === statusEnum[statusEnum.Submitted] ? 'text-red-500' : item?.status === "Canceled" ? 'text-red-900' : ([statusEnum[statusEnum.Assigned], statusEnum[statusEnum.In_Process]].includes(item?.status?.split(' ')?.join('_'))) ? 'text-black' : 'text-green-600'}`}> 
                            {item.status} 
                        </td>
                        <td className="whitespace-nowrap py-3 w-1/7 pr-3 text-xs text-primary-grey-10">{!item?.completed_on ? "" : Methods.convertUTCtoLocalDate(item?.completed_on)?.toString()}</td>
                        <td className="whitespace-nowrap py-3 pr-3 w-1/7 text-xs text-primary-grey-10">{!item?.created_on ? "" : Methods.convertUTCtoLocalDate(item?.created_on)?.toString()}</td>
                        <td className="whitespace-nowrap py-3 w-1/7 pr-3 text-xs text-primary-grey-10">{item?.tat ?? ''}</td>
                    </tr>
                ))}
            </CustomTable>
        </div>
    );
}

export default withAuthentication(DashboardScreen, Paths.login, 1);
