import * as React from 'react';

import DocumentTitle from 'Components/DocumentTitle/DocumentTitle';
import MasterLayout from 'Hoc/MasterLayout';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";
import { Paths } from 'Libraries/Route';
import ApiRequest from 'Services/ApiRequest';
import { IMappingAssumptions, IReportData } from 'Libraries/Interfaces';
import { SpinnerLoader } from 'Components/Loader/Loader';
import GoogleMap from 'Components/Maps/GoogleMap';
import Methods from 'Libraries/CommonMethodsUI';
import { Tabs } from 'Components/Tabs/Tabs';
import withAuthentication from 'Hoc/WithAuthentication';
import HeatMap, { ChoroplethHeatMap} from 'Components/Maps/HeatMap';
import { BarChartSection, GeoCodeConfidenceSection, GuageChartSection, MappingDoughnutSection, TIVChartSection } from './ReportElements';
import { useFetchCurrentSubmission } from 'Hooks/UseFetch';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    Tooltip
);

function ReportAnalytics({ user, navigate }: any): JSX.Element {

    const { controlnumber, version } = useParams<{ controlnumber: string; version: string; }>();
    const { search } = useLocation();

    const [mapType, setMapType] = React.useState<number>(2);

    const [report, setReport] = React.useState<IReportData>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [barChartMode, setBarChartMode] = React.useState<string>("Country");
    const [barChartMode1, setBarChartMode1] = React.useState<string>("Country");

    const { submission } = useFetchCurrentSubmission(controlnumber as string);

    const [mappingsAssumptions, setMappingsAssumptions] = React.useState<IMappingAssumptions>({
        construction_data: [], occupancy_data: []
    });

    async function getReportData(): Promise<void> {
        if (version && controlnumber) {
            setLoading(true);
            try {
                const res = await ApiRequest.getDTReportJson({
                    version: version, control_number: controlnumber
                });
                const result: IReportData = res?.data?.data_transparency_report_json;

                setReport(result ? {
                    ...result,
                    markers: result?.markers?.map((v: any) => {
                        return {
                            count: 100, ID: v?.id, city: v?.data[1], construction: v?.constructCode?.[0], occupancy: v?.occupancyCode?.[0],
                            geocodePreference: v?.geoConfidance, floorArea: v?.floorArea?.[0], countryCode: v?.countryCode?.[0],
                            county: v?.county?.[0], countyTier: v?.countyTier?.[0], lat: v?.lat, lng: v?.lng, postal: v?.postal?.[0],
                            state: v?.state?.[0], yearBuilt: v?.yearBuilt?.[0], noOfStories: v?.noOfStories?.[0], locationName: v?.locName?.[0],
                            streetAddress: v?.data?.[0], covA: v?.coverages?.[0] ?? "", covB: v?.coverages?.[1] ?? "",
                            covC: v?.coverages?.[2] ?? "", covD: v?.coverages?.[3] ?? ""
                        };
                    }) as any[]
                } : undefined);

                if (result) {
                    try {
                        const res = await ApiRequest.getConstOccPieChartData({
                            raw_const_list: result?.raw_const_list?.map(v => v?.toString()) ?? [],
                            raw_occ_list: result?.raw_occ_list?.map(v => v?.toString()) ?? [],
                            const_mapping_data: result.constructionMappings.map((v) => {
                                return { construction: v.construction, mapping: v.mapping, construction_code_id: v.construction_code_id };
                            }),
                            occ_mapping_data: result.occupancyMappings.map((v) => {
                                return { occupancy: v.occupancy, mapping: v.mapping, occupancy_code_id: v.occupancy_code_id };
                            })
                        });
                        setMappingsAssumptions(res.data);
                    }
                    catch(err) {
                        setMappingsAssumptions({ construction_data: [], occupancy_data: [] });                        
                    }
                }
                
                setLoading(false);
            }
            catch(err) {
                setLoading(false);
            }
        }
    };

    React.useEffect(() => {
        getReportData();   
    }, []);

    if (!report || loading || Object.keys(report).length === 0) {
        return (
            <MasterLayout mainTitle={`${submission?.name_insured}`} navigate={() => navigate(`${Paths.dashboard}/${controlnumber}${search}`)} className='px-4 sm:px-6 lg:px-8' userEmail={user?.email as string}>
                <DocumentTitle title={`${submission?.name_insured}`}>
                    <div className="flex flex-col py-5 justify-center items-center">
                        { loading ? <SpinnerLoader adjustment={true} enhance="text-black text-base" /> : (
                            <span className="text-base text-black">Not found.</span>
                        )}
                    </div>
                </DocumentTitle>
            </MasterLayout>
        )
    }

    return (
        <MasterLayout mainTitle={`${submission?.name_insured  ? submission?.name_insured : ''}`} isReportTab={true} navigate={() => navigate(`${Paths.dashboard}/${controlnumber}${search}`)} className='px-2 relative' mainClassName="overflow-hidden" userEmail={user?.email as string}>
            <DocumentTitle title={`${submission?.name_insured  ? submission?.name_insured : ''}`}>
                <div className="grid grid-cols-9 gap-2 h-[90vh] p-2">
                
                    <div className="col-span-4 relative bg-inherit border-2 border-gray-200 rounded-md p-2">
                        <Tabs 
                            activeClassName="mb-2 mr-3 button-39" inActiveClassName="mb-2 mr-3 button-39" onChange={setMapType} active={mapType} 
                            tabs={[ { id: 1, name: 'Google Map' }, { id: 2, name: 'Heat Map' }, { id: 3, name: 'World Map' } ]} 
                        />

                        { (mapType === 1 && report?.markers?.length > 0) ? (
                            <GoogleMap mapHeight='h-[82vh]' markers={report?.markers?.map((v) => {
                                return { 
                                    ...v, 
                                    lat: Math.max(-90, Math.min(90, parseFloat(v.lat))).toFixed(4).toString(), 
                                    lng: (((parseFloat(v.lng) + 180) % 360 + 360) % 360 - 180).toFixed(4).toString(),
                                    tiv: [v?.covA, v?.covB, v?.covC, v?.covD]
                                        .map(val => parseFloat(val) || 0).reduce((sum, num) => sum + Math.round(num), 0)
                                }
                            })?.filter(f => f?.lat !== "90.0000")} />
                        ) : (mapType === 2 && report?.markers?.length > 0) ? (
                            <HeatMap markers={report?.markers?.map((v) => {
                                return { ...v, lat: Math.max(-90, Math.min(90, parseFloat(v.lat))).toFixed(4).toString(), lng: (((parseFloat(v.lng) + 180) % 360 + 360) % 360 - 180).toFixed(4).toString() }
                            })?.filter(f => f?.lat !== "90.0000")} />
                        ) : (mapType === 3 && report?.markers?.length > 0) ? (
                            <ChoroplethHeatMap 
                                countyMatrix={Methods.removeDublicatesInArray(report?.utilityMatrices?.countyMatrix ?? [])} styles={{ width: '100%', height: '82vh' }} 
                                id="clustered-map-4" markers={report?.markers} 
                            />
                        ) : null }
                    </div>

                    <div className="col-span-5 relative bg-inherit h-[90vh]">

                        <div className='grid grid-cols-4 gap-2 h-[16vh]'>
                            <TIVChartSection tiv={report?.totalTiv} coverages={{
                                building: { value: report?.totalCoverages?.[0] ?? 0, percentage: Math.round((report.totalCoverages?.[0] ?? 0 * 100) / report?.totalTiv), },
                                other: { value: report.totalCoverages?.[1] ?? 0, percentage: Math.round((report.totalCoverages?.[1] ?? 0 * 100) / report?.totalTiv), },
                                contents: { value: report.totalCoverages?.[2] ?? 0, percentage: Math.round((report.totalCoverages?.[2] ?? 0 * 100) / report?.totalTiv), },
                                bi: { value: report.totalCoverages?.[3] ?? 0, percentage: Math.round((report.totalCoverages?.[3] ?? 0 * 100) / report?.totalTiv), }
                            }} />
                            <GuageChartSection report={report} />
                        </div>

                        <div className='relative col-span-3 border-2 border-gray-200 rounded-md pt-2 pl-2 my-2 h-[46vh]'>
                            <BarChartSection 
                                barChartMode={barChartMode} setBarChartMode={setBarChartMode} 
                                byLocationOrTiv={true} storedFilters={[]}
                                barChartMode1={barChartMode1} setBarChartMode1={setBarChartMode1}
                                isModifiedData={true} report={{ ...report, utilityMatrices: {
                                ...report?.utilityMatrices, 
                                constructionMatrix: mappingsAssumptions.construction_data.map((v) => {
                                    const finder: string[] = [...new Set(report?.constructionMappings.filter((f) => f.mapping?.toString().trim() === v.mappings?.toString().trim()).map(f => f.construction?.toString().trim()))];
                                    const matcher = report?.utilityMatrices?.constructionMatrix?.filter((f) => finder.includes(f.label?.toString()?.trim()));
                                
                                    return {
                                        label: v.mappings, ...Methods.sumOfArrayObjectsForMappings(matcher)
                                    }
                                }),
                                occupancyMatrix: mappingsAssumptions.occupancy_data.map((v) => {
                                    const finder: string[] = [...new Set(report?.occupancyMappings.filter((f) => f.mapping?.toString().trim() === v.mappings?.toString().trim()).map(f => f.occupancy?.toString().trim()))];
                                    const matcher = report?.utilityMatrices?.occupancyMatrix?.filter((f) => finder.includes(f.label?.toString().trim()));
                                
                                    return {
                                        label: v.mappings, ...Methods.sumOfArrayObjectsForMappings(matcher)
                                    }
                                }),
                                countyMatrix: report?.utilityMatrices?.countyMatrix?.map((v) => {
                                    const finder = report?.markers?.find(f => f?.county === v?.label);
                                    return { ...v, label: `${v?.label}, ${finder?.state}` };
                                })
                            } }} />
                        </div>

                        <div className='grid grid-cols-3 gap-2 h-[26vh]'>
                            <div className='relative col-span-1 border-2 border-gray-200 rounded-md p-2'>
                                <GeoCodeConfidenceSection 
                                    byLocationOrTiv={false} storedFilters={[]}
                                    data={report?.geoconfidenceSummary?.map((s, i: number) => {
                                        const percentage: number = ((s?.count * 100) / Methods.arrayValuesSum(report?.geoconfidenceSummary.map((v) => v.count)));
                                        return { label: s?.confidence, count: s?.count, percentage: percentage % 2 === 0 ? percentage : parseFloat(percentage.toFixed(1)) }                                
                                    })} 
                                />
                            </div>
                            {[
                                { id: 1, title: 'Construction', value: mappingsAssumptions.construction_data },
                                { id: 2, title: 'Occupancy', value: mappingsAssumptions.occupancy_data }
                            ].map((obj) => (
                                <div key={obj.id} className='relative col-span-1 border-2 border-gray-200 rounded-md p-2'>
                                    <MappingDoughnutSection
                                        storedFilters={[]}
                                        activeTab={obj.id} item={{ title: obj.title, value: obj.value?.length ?? 0 }} chartId={`mappings-modal-${obj.id}`}
                                        data={obj.value as any} tableData={obj.id === 1 ? report?.constructionMappings : report?.occupancyMappings}
                                    />
                                </div>
                            ))}
                        </div>

                    </div>

                </div>

            </DocumentTitle>
        </MasterLayout>
    );
}

export default withAuthentication(ReportAnalytics, Paths.login, 1);