import React from 'react';

import Methods from 'Libraries/CommonMethodsUI';

export default function TIVLegends({ data }: { data: { start: number; end: number; color: string; }[]; }): JSX.Element {
    return (
        <div className='flex flex-col bg-colors-warmGray-100 rounded-md p-2 max-h-56 overflow-y-auto remove-scrollbar'>
            {data.filter(f => f.end).map((v) => (
                <div key={v.start} className='flex flex-row items-center justify-start mb-1.5'>
                    <span className="mr-2 w-3 h-3 rounded-full" style={{ 
                        backgroundColor: v.color,
                        border: '1px solid black'
                    }}></span>
                    <span className='text-xs text-black font-medium'> {Methods.numberFormatter(Math.round(v.start), 2)} - {Methods.numberFormatter(v.end, 2)} </span>
                </div>
            ))}
        </div>
    );
}