export const Paths = {
    undefinedRoute: '*',
    main: '/' || '',
    accessDenied: '/access-denied',
    notfound: '/page-not-found',
    pathOnward: ':path?',
    login: '/login',
    signup: '/signup',
    dashboard: '/dashboard',
    companies: '/companies',
    forgotPassword: '/forgot-password',
    forgotPasswordConfirm: '/forgot-password-confirm',
    profile: '/profile',
    controlNumber: ':controlnumber',
    version: ':version',
    templateHistory: '/templatehistory',
    users: '/users',
    reportedConstruction: '/reportedconstruction',
    reportedOccupancy: '/reportedoccupancy',
    subscription: '/subscription',
    triage: '/triage',
    invoice: '/invoice',
    stp: '/upload',
    report: '/report',
    systematic: "systematic"
}
