import * as React from 'react';

import { SpinnerLoader } from 'Components/Loader/Loader';
import Methods from 'Libraries/CommonMethodsUI';
import { ICommentItem } from 'Libraries/Interfaces';
import ApiRequest from 'Services/ApiRequest';
import { Tooltip } from 'react-tooltip';
import { TfiArrowCircleRight } from 'react-icons/tfi'; 

const CommentItem: React.FC<{ commentItem: ICommentItem }> = ({ commentItem }) => {
    const c: string = commentItem.display_color;
    return (
        <div className="flex flex-col justify-start border-b-2 border-primary-grey mb-5 pb-5">
            <div className='inline-flex items-center justify-start mb-1'>
                <div 
                    data-tooltip-id="comment-item"
                    data-tooltip-content={commentItem.user}
                    data-tooltip-place="bottom-start" style={{ backgroundColor: c }}
                    className={`inline-flex items-center justify-center w-7 h-7 cursor-pointer text-xs rounded-full text-white`}
                >
                    { commentItem.user.charAt(0).toUpperCase() }
                </div>
                <span className="text-primary-grey-10 ml-4 text-xs">{Methods.convertUTCtoLocalDate(commentItem.created_on)}</span>
            </div>
            <span style={{ color: c }} className={`text-xs block break-words pl-1`}>
                { commentItem.comment }
            </span>
            <Tooltip id="comment-item" arrowColor='#000' />
        </div>
    );
}

export const CommentTab: React.FC<{ versionId: string | undefined; id: number | undefined; controlNumber: string; }> = ({ versionId, id, controlNumber }) => {

    const [loading, setLoading] = React.useState<boolean>(true);
    const [text, setText] = React.useState<string>('');
    const [comments, setComments] = React.useState<ICommentItem[]>([]);

    const get = React.useCallback(async (flag: boolean) => {
        setLoading(flag);
        try {
            const res = await ApiRequest.getVersionComments({
                version: versionId, control_number: controlNumber
            });
            setComments(res.data);
            setLoading(false);
        }
        catch(e) {
            setLoading(false);            
        }
    }, [versionId, controlNumber]);

    React.useEffect(() => {
        if(versionId) {
            setText('');
            get(true);
        }
    }, [versionId, controlNumber, get]);

    const onSubmitComment = React.useCallback(async () => {
        if (text){
            try {
                const res = await ApiRequest.addVersionComments({ comment: text, submission_version_id: parseInt(id as any) });
                setComments([res.data, ...comments]);
                setText('');
            }
            catch (err) {}
        }
    }, [id, text, comments]);

    return (
        <div className="flex flex-col justify-start">
            { loading ? (
                <SpinnerLoader adjustment={true} enhance="text-black text-sm my-3" />
            ) : comments?.length === 0 ? (
                <span className='text-sm text-black text-center mb-4'>No comments available.</span>
            ) : comments?.map((item, i) => (
                <CommentItem key={i} commentItem={item} />
            )) }

            <div className='sticky bottom-0 bg-[#FEFCFF] z-20 py-2'>
                <div className='relative'>
                    <input
                        type="text" name="new-comment-field" id="new-comment-field" placeholder="Add comment..."
                        value={text} onChange={(e) => setText(e.target.value)} required
                        className="block w-full py-2 pl-4 pr-8 rounded-full border border-primary-grey bg-inherit text-xs text-black font-normal placeholder:text-primary-grey focus:ring-0"                            
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onSubmitComment();
                            }
                        }}
                    />
                    <TfiArrowCircleRight onClick={() => onSubmitComment()} className='absolute bg-[#B5B5B5] transition-all duration-300 ease-in-out hover:text-black rounded-full text-white top-1/4 right-3 text-base cursor-pointer' />
                </div>
            </div>
        </div>
    );
}
